export const FACTORY_HEALTH_TYPES = {
  WIP: 'WIP',
  THROUGHPUT: 'THROUGHPUT',
};

export const FACTORY_HEALTH_ACCORDIONS = {
  ACTUALS: 'ACTUALS',
  TARGETS: 'TARGETS',
  DEVIATION_THRESHOLD: 'DEVIATION_THRESHOLD',
};

export const ACCORDION_STATUS = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
};

export const MESSAGE_STRINGS = {
  'CONFIG_HEADER.Parameter': 'Parameter',
  'CONFIG_HEADER.Value': 'Value',
  'CONFIG_HEADER.UoM': 'UoM',
  'CONFIG_HEADER.Metric': 'Metric',
  'CONFIG_HEADER.Deviation_from_target': 'Deviation from Target',

  // FACTORY HEALTH
  'FACTORY_HEALTH.type.wip': 'WIP',
  'FACTORY_HEALTH.type.throughput': 'Throughput',
  'THROUGHPUT_TIME_RANGE.shift': 'Shift',
  'THROUGHPUT_TIME_RANGE.production_day': 'Production Day',

  //ACTUALS
  ACTUALS_ACCORDIAN_HEADER: 'Actuals',
  ACTUALS_TIME_RANGE: 'Time Range',
  ACTUALS_UOM: 'UoM',
  ACTUALS_REFRESH_RATE: 'Refresh Rate',
  'ACTUALS_REFRESH_RATE_UNIT.seconds': 'Seconds',
  'ACTUALS_REFRESH_RATE_UNIT.minutes': 'Minutes',
  'ACTUALS_REFRESH_RATE_UNIT.hours': 'Hours',

  //TARGETS
  TARGETS_THROUGHPUT_ACCORDIAN_HEADER: 'Throughputs per Production Day',
  TARGETS_WIP_ACCORDIAN_HEADER: 'Targets',
  'THROUGHPUT.TARGETS.uploadXlsx': 'Upload Throughputs XLSX',
  'WIP.TARGETS.uploadXlsx': 'Upload Targets WIP XLSX',
  'FACTORY_HEALTH.tree.header.WIP.header': 'WIP Target',
  'FACTORY_HEALTH.tree.header.THROUGHPUT.header': 'Target',
  'FACTORY_HEALTH.tree.header.THROUGHPUT.maxDemonstratedThroughput':
    'Max Demonstrated',
  'FACTORY_HEALTH.tree.header.THROUGHPUT.maxTheoreticalThroughput':
    'Max Theoretical',
  'FACTORY_HEALTH.tree.header.THROUGHPUT.maxDemonstratedThroughput.tooltip': [
    'Max Demonstrated Throughput',
    ' is the maximum Throughput value that has been achieved in a Production Day. Values will need to be set at the Cell, Zone, Line, Area, and Plant level.',
  ],
  'FACTORY_HEALTH.tree.header.THROUGHPUT.maxTheoreticalThroughput.tooltip': [
    'Max Theoretical Throughput',
    ' is the maximum Throughput value that is expected in a Production Day. Values will need to be set at the Cell, Zone, Line, Area, and Plant level.',
  ],
  'Factory_HEALTH.tree.header.equipmentHierarchy': 'Equipment Hierarchy',
  'Factory_HEALTH.tree.header.uom': 'UoM',
  'FACTORY_HEALTH.tree.header.WIP.tooltip': [
    'WIP',
    ' is a constant target value that you want to maintain throughout the production day, not a target value expected at the end of the production day.',
  ],
  'FACTORY_HEALTH.tree.header.THROUGHPUT.tooltip': [
    'Target Throughput',
    ' is the expected value at the end of the Production Day. Values will need to be set at the Cell, Zone, Line, Area, and Plant level.',
  ],
  'Targets.reupload.preview.text': 'Re-Upload',
  TARGETS_AIP_SYNC_ALERT:
    'There has been an update on the Equipment Intelligence table on the General Configuration screen. Please re-upload the Targets.',
  'Targets.upload.error.text.prefix': 'View',
  'Targets.upload.error.text.suffix': ' errors. Please correct and re-upload.',
  'Targets.Alert.ScheduleUpdate':
    'There has been an update on the Schedule table on the General Configuration screen. Please re-upload the Targets.',

  //DEVIATION THRESHOLD
  DEVIATION_THRESHOLD_ACCORDION_HEADER: 'Deviation Threshold',
  'DEVIATION.info':
    'Lower Bound and Upper Bound thresholds for Red, Amber and Green must be set to indicate significance of the Actual values deviation from the configured Target values.',

  //ERROR MESSAGES
  'ERROR_MESSAGES.secondsValidation':
    'Refresh Rate must be 90 seconds or more.',
  'ERROR_MESSAGES.minutesValidation': 'Refresh Rate must be 5 minutes or more.',
  'ERROR_MESSAGES.hoursValidation': 'Refresh Rate must be 1 hour or more.',
  'ERROR_MESSAGES.theoreticalGreaterThanDemonstratedValidation':
    'Invalid Input: Max Demonstrated Capacity / Production Day cannot be greater than Max Theoretical Capacity / Production Day.',
  'ERROR_MESSAGES.mandatoryFieldsValidation':
    'Please complete the required fields.',
  'Factory_HEALTH.tree.loading.error':
    'There was trouble fetching hierarchy information',

  'TARGETS_UPLOAD.message':
    'If you have already uploaded a XLSX file, clicking this icon again will allow you to upload a new XLSX that will replace the previous XLSX file. All data from the first XLSX file will be removed.',

  //TOAST MESSAGES
  'Toast.message.SUCCESS': 'Your changes have been successfully saved!',
  'Toast.message.ERROR': 'Something went wrong. Please try again later!',
  'Toast.message.WARNING': 'Oops, something went wrong!',
  'Toast.message.INFO': 'Please wait',

  //TARGETS TOASTS MESSAGES
  'Targets.upload.SUCCESS': 'File has been successfully uploaded!',

  //BUTTON MESSAGES
  'Targets.upload.ErrorList.okay': 'Okay',
  'Targets.upload.ErrorList.title': 'File Upload Errors',
};

export const ACTUALS_ID = {
  TIME_RANGE_SELECTION: 'dropdown-timerange',
  TIME_RANGE_ITEMS: 'timerange-items',
  REFRESH_RATE_UNIT_SELECTION: 'dropdown-refresh-rate',
  REFERSH_RATE_UNIT_ITEMS: 'refresh-rate-items',
  REFRESH_RATE_TEXTFIELD: 'textfield-refreshRate',
};

export const ACTUALS_CONSTANTS = {
  REFRESH_RATE_UNIT_SECONDS: 'Seconds',
  REFRESH_RATE_UNIT_MINUTES: 'Minutes',
  REFRESH_RATE_UNIT_HOURS: 'Hours',
  TIME_RANGE_SHIFT: 'Shift',
  TIME_RANG_PRODUCTION_DAY: 'Production Day',
  DEFAULT_DROPDOWN_VALUE: 'Select Value',
};

export const FACTORY_HEALTH_ID = {
  FACTORY_HEALTH_SELECTION: 'dropdown-factoryHealth',
  FACTORY_HEALTH_ITEMS: 'factoryHealth-items',
  FACTORY_HEALTH_ACTUALS: 'ACTUALS',
  FACTORY_HEALTH_TARGETS: 'TARGETS',
  FACTORY_HEALTH_DEVIATION_THRESHOLD: 'Deviation Threshold',
};

export const DEFAULT_DROPDOWN_VALUE = 'Select Value';
export const DEFAULT_UOM = 'Units';
export const DEFAULT_ENTITY_ID = '085b820b-ee9b-4f82-8a12-fa2d4b34e69f';

export const QUERY_CONSTANTS = {
  GET_ACTUALS: 'getActuals',
  UPDATE_ACTUALS: 'updateActuals',
  GET_EXPORT_URL: 'getDownloadUrl',
  GET_UPLOAD_URL: 'getUploadUrl',
  VALIDATE_UPLOADED_FILE: 'validatedXlsxFile',
  GET_TARGETS_STATUS: 'getTargetsStatus',
  GET_TARGETS_VALUE_HIERARCHY: 'getTargetsValueHierarchy',
  GET_DEVIATION_THRESHOLDS: 'getDeviationThresholds',
  UPDATE_DEVIATION_THRESHOLDS: 'updateDeviationThresholds',
  GET_CONFIG_STATUSES: 'getFactoryConfigStatuses',
};

export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const TOAST_REDUCER_CONSTANTS = {
  SHOW_SUCCESS_TOAST: 'SHOW_SUCCESS_TOAST',
  SHOW_ERROR_TOAST: 'SHOW_ERROR_TOAST',
  SHOW_WARNING_TOAST: 'SHOW_WARNING_TOAST',
  SHOW_INFO_TOAST: 'SHOW_INFO_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
};

export const FILE_CONTENT_TYPES = {
  CSV: 'text/csv',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const FILE_UPLOAD_STATUSES = {
  DONE: 'done',
  UPLOADING: 'uploading',
  ABORTED: 'aborted',
  RESTARTED: 'restarted',
  REMOVED: 'removed',
  STARTED: 'started',
  READY: 'ready',
  PREPARING: 'preparing',
  GETTING_UPLOAD_PARAMS: 'getting_upload_params',
  HEADERS_RECEIVED: 'headers_received',
  REJECTED_FILE: 'rejected_file_type',
  UPLOAD_PARAMS_ERROR: 'error_upload_params',
};

export const FILE_ACCEPT_TYPES = {
  XLSX: '.xlsx',
};

export const CONFIG_NAME = 'factoryHealth';

export const ENTITY_TYPES = {
  PLANT: 'Plant',
  AREA: 'Area',
  ZONE: 'Zone',
  CELL: 'Cell',
};

export const TARGETS_LABELS = {
  UPLOAD_BUTTON: 'Upload XLSX',
  EXPORT_BUTTON: 'Export Data',
};

export const TEST_IDS = {
  TREE_HEADER_THROUGHPUT_TOOLTIP: 'throughput-table-tooltip',
  THROUGHPUT_ACCORDION_HEADER: 'throughput-accordion-header',
  ERROR_LIST: 'error-list',
  ERROR_LIST_ITEM: 'error-list-item',
  ERROR_MODAL: 'error-modal',
  MULTIPLE_ERROR_BTN: 'multiple-error-btn',
  ERROR_MODAL_BTN: 'error-modal-btn',
  ERROR_MODAL_LIST_ITEM: 'error-modal-list-item',
};

export const BUTTON_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};
export const BUTTON_SIZE = {
  STANDARD: 'standard',
  SMALL: 'small',
};
