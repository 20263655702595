import {
  Box,
  ButtonBase,
  CircularProgress,
  LinearProgress,
  Typography,
  styled,
} from '@mui/material';
import React from 'react';
import { MESSAGE_STRINGS } from '../../constants/FactoryHealthConstants';
import XLSXIcon from '../../assets/img/xlsxIcon.svg';
import GreenUploadIcon from '../../assets/img/greenUploadIcon.svg';
import ExclamationMark from '../../assets/img/exclamationMark.svg';

const ProgressContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.elevationGrey,
  padding: '1em',
  boxShadow: '0 0.25em 0.25em rgba(0, 0, 0, 0.25)',
  borderRadius: '0.25em',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '70%',
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  '& .MuiLinearProgress-colorPrimary': {
    backgroundColor: theme.customColors.progressBarEmpty,
    borderRadius: '1em',
    height: '0.35em',
  },
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.customColors.progressBarBlue,
    borderRadius: '1em',
    height: '0.35em',
  },
}));

const SmallUploadIcon = styled('div')(() => ({
  height: '3.125em',
  width: '3.125em',
}));

const UploadPreview = ({
  previewComponentProps,
  handleReupload,
  isValidateTargetsError = false,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { meta: { name, size, percent } = {}, fileWithMeta } =
    previewComponentProps;
  return (
    <div>
      {!isValidateTargetsError ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress
            sx={{ color: 'white', marginRight: '1em' }}
            size="1.5em"
          />
          <Typography>Loading Data ...</Typography>
        </div>
      ) : (
        <Box className="fhc-preview-reupload-container">
          <div className="fhc-preview-container">
            <ProgressContainer>
              <div className="fhc-preview-container-child-icon ">
                <XLSXIcon width="2.708rem" height="3.25rem" alt="xlsx-icon" />
              </div>
              <Box className="fhc-preview-container-child-info ">
                <Box className="fhc-preview-container-child-title">
                  <div>{name}</div>
                </Box>
                <StyledLinearProgress
                  variant="determinate"
                  color="primary"
                  value={Math.round(percent)}
                />
              </Box>
              <div className="fhc-preview-container-child-error-icon">
                <ExclamationMark height="2rem" width="2rem" alt="error-icon" />
              </div>
            </ProgressContainer>
          </div>
          <div className="fhc-preview-reuploader">
            <ButtonBase
              onClick={() => handleReupload(fileWithMeta)}
              disableRipple
            >
              <SmallUploadIcon>
                <GreenUploadIcon
                  alt="reupload-icon"
                  className="fhc-preview-reuploader-icon"
                />
              </SmallUploadIcon>
            </ButtonBase>
            <Typography sx={{ marginTop: '1em' }}>
              {MESSAGE_STRINGS['Targets.reupload.preview.text']}
            </Typography>
          </div>
        </Box>
      )}
    </div>
  );
};

export default UploadPreview;
