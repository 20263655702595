import { Select, InputBase, styled } from '@mui/material';
import PropTypes from 'prop-types';
import DownArrowSVG from '../../assets/img/downArrow.svg';
import { DEFAULT_DROPDOWN_VALUE } from '../../constants/FactoryHealthConstants';
import './selectDropDown.css';

const CustomizedInputBase = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'borderradius' && prop !== 'error',
})(({ theme, borderradius: dropdownRadius, error }) => ({
  display: 'flex',
  width: '10em',
  '& .Mui-disabled': {
    color: theme.palette.background.shuttleGray,
  },
  color: theme.palette.background.shuttleGray,
  '& .MuiSelect-iconOutlined': {
    marginRight: '0.5em',
    top: 'inherit',
  },
  '& .MuiSelect-icon.Mui-disabled': {
    filter: 'opacity(0.5)',
    '& path': { fill: theme.palette.background.shuttleGray },
  },
  '& .MuiInputBase-input': {
    border: `.0625em solid ${
      error ? theme.palette.text.errorText : theme.palette.border.darkGrey
    }`,
    padding: 3.5,
    width: '100%',
    paddingLeft: '1em',
    '&.MuiSelect-select': {
      borderRadius: dropdownRadius,
      backgroundColor: theme.palette.background.configLighterDark,
    },
    '&:focus': {
      border: `.0625em solid ${theme.palette.selected.greenSelect}`,
    },
    '&[aria-expanded=true]': {
      border: `.0625em solid ${theme.palette.selected.greenSelect}`,
      color: theme.palette.selected.greenSelect,
    },
    '&.Mui-disabled': {
      WebkitTextFillColor: 'inherit', // Was overriding given disabled color
    },
  },
  '& .Mui-error': {
    borderColor: theme.palette.text.errorText,
    '&:focus': {
      borderColor: theme.palette.text.errorText,
    },
  },
}));

const DDPlaceholder = styled('div')(({ theme }) => ({
  color: theme.palette.text.nobel,
}));

const SelectDropdown = ({
  error = false,
  value = '',
  onChange,
  children,
  disabled = false,
  placeholderValue,
  borderRadius = 20,
  menuHeight = '15.75em',
  ...overides
}) => {
  return (
    <Select
      error={error}
      disabled={disabled}
      data-testid="select-dropdown"
      value={value}
      onChange={onChange}
      variant="outlined"
      input={<CustomizedInputBase error={error} borderradius={borderRadius} />}
      renderValue={
        value !== placeholderValue
          ? undefined
          : () => <DDPlaceholder>{DEFAULT_DROPDOWN_VALUE}</DDPlaceholder>
      }
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        sx: { maxHeight: menuHeight },
      }}
      IconComponent={() => <DownArrowSVG className="fhc-dropDownArrowIcon" />}
      {...overides}
    >
      {children}
    </Select>
  );
};

SelectDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  placeholderValue: PropTypes.string,
  maxHeight: PropTypes.string,
  borderRadius: PropTypes.number,
  menuHeight: PropTypes.string,
  error: PropTypes.bool,
};
export default SelectDropdown;
