import { factoryHealth } from '@smf/ui-util-app';

export async function getActuals(entityId, utility) {
  return await factoryHealth.getActuals(entityId, utility);
}

export async function updateActuals(body) {
  return await factoryHealth.updateActuals(body);
}

export async function getPresignedExportUrl(entityId, utility) {
  return await factoryHealth.getPresignedExportUrl(entityId, utility);
}

export async function getPresignedUploadUrl(params) {
  return await factoryHealth.getPresignedUploadUrl(params);
}

export async function validateUpload(params) {
  return await factoryHealth.validateUpload(params);
}

export async function getTargetsStatus(entityId, utility) {
  return await factoryHealth.getTargetsStatus(entityId, utility);
}

export async function getTargetsValueHierarchy(entityId, utility) {
  return await factoryHealth.getTargetsValueHierarchy(entityId, utility);
}

export async function getDeviationThresholds(entityId, utility) {
  return await factoryHealth.getDeviationThresholds(entityId, utility);
}

export async function updateDeviationThresholds(body) {
  return await factoryHealth.updateDeviationThresholds(body);
}

export async function getFactoryConfigStatuses(entityId) {
  return await factoryHealth.getFactoryConfigStatuses(entityId);
}
