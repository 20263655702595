import { useState, useContext, forwardRef } from 'react';
import FactoryHealthTypeContext from '../../context/factoryHealthContext';
import { Box } from '@mui/material';
import {
  FILE_CONTENT_TYPES,
  FILE_ACCEPT_TYPES,
  FILE_UPLOAD_STATUSES,
  ACCORDION_STATUS,
} from '../../constants/FactoryHealthConstants';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone-uploader';
import { convertCase, getUtilityName } from '../../utils/helpers';
import { getPresignedUploadUrl } from '../../utils/apiHelper';
import ErrorList from '../../components/ErrorList';
import UploadInput from '../../components/UploadInput';
import UploadPreview from '../../components/UploadPreview';
import CustomBackdrop from '../../styledComponents/CustomBackdrop';
import DashedBorder from '../../styledComponents/DashedBorder';
import Reuploader from './Reuploader';
import TargetsTable from './TargetsTable';
import ErrorListModal from '../../components/ErrorList/MultipleErrorModal';

const ReuploaderAndHierarchyTable = forwardRef((props, ref) => {
  const {
    fetchDownloadUrl,
    validateTargets,
    errorList,
    isUploadError,
    setIsUploadError,
    resetValidateTargets,
    isValidateTargetsLoading,
    isValidationSuccess,
    isNewFileUploaded,
    setIsNewFileUploaded,
    showTable,
    setShowTable,
    onSecondaryUpload,
    setFileWithMeta,
    updateAccordionStatus,
    entityId,
  } = props;
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [showMultipleErrorModal, setShowMultipleErrorModal] = useState(false);
  const factoryHealthType = useContext(FactoryHealthTypeContext);
  const handleChangeStatus = (fileDetails, status) => {
    if (status === FILE_UPLOAD_STATUSES.DONE) {
      setFileWithMeta(fileDetails);
      setIsFileUploading(false);
      validateTargets(fileDetails.meta.name);
    }
    if (
      status === FILE_UPLOAD_STATUSES.PREPARING ||
      status === FILE_UPLOAD_STATUSES.GETTING_UPLOAD_PARAMS ||
      status === FILE_UPLOAD_STATUSES.UPLOADING
    ) {
      updateAccordionStatus(convertCase(ACCORDION_STATUS.IN_PROGRESS));
      setIsFileUploading(true);
      setShowTable(false);
    }
  };
  const getReuploadUrl = async (file) => {
    const params = {
      utility: getUtilityName(factoryHealthType),
      contentType: FILE_CONTENT_TYPES.XLSX,
      fileName: file,
      entityId: entityId,
    };
    const result = await getPresignedUploadUrl(params);
    return result.data;
  };
  const getUploadParams = async (params) => {
    const { file, meta } = params;
    const uploadUrl = await getReuploadUrl(meta.name);
    return {
      body: file,
      url: uploadUrl,
      meta,
      method: 'PUT',
      headers: {
        'Content-Type': FILE_CONTENT_TYPES.XLSX,
      },
    };
  };
  const handleReupload = (fileWithMeta) => {
    fileWithMeta.remove();
    ref.current?.dropzone?.current?.firstChild?.lastChild?.click();
    setIsUploadError(false);
    resetValidateTargets();
  };
  const backdropLoader = isFileUploading || isValidateTargetsLoading;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Reuploader
        fetchDownloadUrl={fetchDownloadUrl}
        onSecondaryUpload={onSecondaryUpload}
      />
      {isUploadError && (
        <ErrorList
          errorList={errorList}
          isMultipleErrors={Boolean(errorList.length > 3)}
          setShowMultipleErrorModal={setShowMultipleErrorModal}
          sx={{ marginBottom: '1rem' }}
        />
      )}
      {showTable && (
        <TargetsTable
          isValidationSuccess={isValidationSuccess}
          entityId={entityId}
          isNewFileUploaded={isNewFileUploaded}
          setIsNewFileUploaded={setIsNewFileUploaded}
        />
      )}
      <Box
        sx={{
          display: showTable ? 'none' : 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DashedBorder
          sx={{
            minHeight: '13.625em',
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '3em',
            marginBottom: '3em',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Dropzone
              ref={ref}
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              maxFiles={1}
              SubmitButtonComponent={() => null}
              PreviewComponent={(previewProps) => (
                <UploadPreview
                  previewComponentProps={previewProps}
                  handleReupload={handleReupload}
                  isValidateTargetsError={isUploadError}
                  isFilePreparing={isFileUploading}
                />
              )}
              InputComponent={UploadInput}
              accept={FILE_ACCEPT_TYPES.XLSX}
              multiple={false}
              canRestart
            />
          </Box>
        </DashedBorder>
      </Box>
      <Box display="flex" maxHeight="100%" flex={1} flexDirection="column">
        <CustomBackdrop open={backdropLoader || showMultipleErrorModal}>
          {showMultipleErrorModal && (
            <ErrorListModal
              errorList={errorList}
              setShowMultipleErrorModal={setShowMultipleErrorModal}
            />
          )}
        </CustomBackdrop>
      </Box>
    </Box>
  );
});
ReuploaderAndHierarchyTable.displayName = ReuploaderAndHierarchyTable;
ReuploaderAndHierarchyTable.propTypes = {
  fetchDownloadUrl: PropTypes.func,
  validateTargets: PropTypes.func,
  errorList: PropTypes.array,
  isUploadError: PropTypes.bool,
  setIsUploadError: PropTypes.func,
  resetValidateTargets: PropTypes.func,
  isValidateTargetsLoading: PropTypes.bool,
  isValidationSuccess: PropTypes.bool,
  onSecondaryUpload: PropTypes.func,
  showTable: PropTypes.bool,
  isNewFileUploaded: PropTypes.bool,
  setIsNewFileUploaded: PropTypes.func,
  setShowTable: PropTypes.func,
  setFileWithMeta: PropTypes.func,
  removeExisitingFileData: PropTypes.func,
  updateAccordionStatus: PropTypes.func,
  entityId: PropTypes.string,
};

export default ReuploaderAndHierarchyTable;
