import { useState, useEffect, useContext, useRef } from 'react';
import FactoryHealthTypeContext from '../../context/factoryHealthContext';
import {
  FACTORY_HEALTH_ID,
  FACTORY_HEALTH_TYPES,
  FACTORY_HEALTH_ACCORDIONS,
  MESSAGE_STRINGS,
  ACCORDION_STATUS,
  QUERY_CONSTANTS,
  FILE_CONTENT_TYPES,
  TOAST_REDUCER_CONSTANTS,
  TEST_IDS,
} from '../../constants/FactoryHealthConstants';
import DownArrow from '../../assets/img/downArrow.svg';
import ExclamationMark from '../../assets/img/exclamation.svg';
import { Box, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import Accordian from '../../components/Accordion/index';
import AccordionStatus from '../../components/AccordionStatus';
import '../../utils/commonStyles/commonStyles.css';
import InitialUploader from './InitialUploader';
import ReuploaderAndHierarchyTable from './ReuploaderAndHierarchyTable';
import { useMutation, useQuery } from 'react-query';
import {
  convertCase,
  getAlertMessage,
  getUtilityName,
  isAlertMessageShown,
  simulateUrlClick,
} from '../../utils/helpers';
import { useToastContext } from '../../context/toastContext';
import {
  getPresignedExportUrl,
  getTargetsStatus,
  validateUpload,
} from '../../utils/apiHelper';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import CustomBackdrop from '../../styledComponents/CustomBackdrop';
import AccTitleTypography from '../../styledComponents/AccTitleTypography';
import './targets.css';

const AlertText = styled(Typography)(({ theme }) => ({
  fontSize: '0.875em ',
  color: theme.palette.text.lightYellowishGray,
}));

const Targets = ({
  onExpandUpdate,
  isExpanded,
  fetchConfigStatuses,
  selectedFactoryHealthType,
}) => {
  const factoryHealthType = useContext(FactoryHealthTypeContext);
  const [accordionStatus, setAccordionStatus] = useState(
    ACCORDION_STATUS.NOT_STARTED
  );
  const [isEIFileUploadedForWIP, setIsEIFileUploadedForWIP] = useState(false);
  const [isEIFileUploadedForThroughput, setIsEIFileUploadedForThroughput] =
    useState(false);
  const [isRespectiveFileUploaded, setIsRespectiveFileUploaded] =
    useState(false);
  const [isTargetInSyncWithAIP, setIsTargetInSyncWithAIP] = useState(true);
  const [isNewFileUploaded, setIsNewFileUploaded] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const { toastDispatch } = useToastContext();
  const [isUploadError, setIsUploadError] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const dropZoneRef = useRef(null);
  const [fileWithMeta, setFileWithMeta] = useState(null);
  const [
    isEIFileUploadedWithValidPurposeTags,
    setIsEIFileUploadedWithValidPurposeTags,
  ] = useState(true);

  const [isTargetInSyncWithSchedule, setIsTargetInSyncWithSchedule] =
    useState(true);

  const { rxjsState } = useRxjsState();
  const [entityId, setEntityId] = useState(rxjsState.plantId);

  useEffect(() => {
    const respectiveFileUploadStatus =
      (factoryHealthType === FACTORY_HEALTH_TYPES.THROUGHPUT &&
        isEIFileUploadedForThroughput) ||
      (factoryHealthType === FACTORY_HEALTH_TYPES.WIP &&
        isEIFileUploadedForWIP);
    setIsRespectiveFileUploaded(respectiveFileUploadStatus);
  }, [
    isEIFileUploadedForWIP,
    isEIFileUploadedForThroughput,
    factoryHealthType,
  ]);

  useEffect(() => {
    if (rxjsState.plantId) {
      setEntityId(rxjsState.plantId);
    }
  }, [rxjsState.plantId]);

  useEffect(() => {
    if (!isValidateTargetsLoading && isValidationSuccess) {
      removeExisitingFileData(fileWithMeta);
    }
  });
  function removeExisitingFileData(fileWithMetaToBeRemoved) {
    if (fileWithMetaToBeRemoved) {
      fileWithMetaToBeRemoved.remove();
    }
  }
  function openUploadCsv() {
    if (dropZoneRef.current) {
      dropZoneRef.current?.dropzone?.current?.firstChild?.lastChild?.click();
    }
  }

  function onSecondaryUpload() {
    openUploadCsv();
  }
  useEffect(() => {
    setAccordionStatus(ACCORDION_STATUS.NOT_STARTED);
    setIsUploadError(false);
    setIsTargetInSyncWithAIP(true);
    setIsTargetInSyncWithSchedule(true);
    setErrorList([]);
  }, [factoryHealthType]);

  const {
    isFetching: isTargetsStatusFetching,
    isLoading: isTargetsStatusLoading,
  } = useQuery(
    [QUERY_CONSTANTS.GET_TARGETS_STATUS, factoryHealthType, entityId],
    async () => {
      return await getTargetsStatus(
        entityId,
        getUtilityName(factoryHealthType)
      );
    },
    {
      onSuccess: (response) => {
        const status = convertCase(response.targets.status);
        setAccordionStatus(status);
        setIsEIFileUploadedForWIP(response.targets.isEIFileUploadedForWIP);
        setIsEIFileUploadedForThroughput(
          response.targets.isEIFileUploadedForThroughput
        );
        setIsEIFileUploadedWithValidPurposeTags(
          response.isEIFileUploadedWithValidPurposeTags
        );
        if (!response.isEIFileUploadedWithValidPurposeTags) {
          fetchConfigStatuses();
        }
        if (factoryHealthType === FACTORY_HEALTH_TYPES.THROUGHPUT) {
          setShowTable(response.targets.isEIFileUploadedForThroughput);
          setIsTargetInSyncWithAIP(
            response.targets.isTargetThroughputInSyncWithAIP
          );
          setIsTargetInSyncWithSchedule(
            !(response?.targets?.isTargetThroughputInSyncWithSchedule === false) // Undefined consider it as true. Consider strict false value. All other will be true
          );
        } else if (factoryHealthType === FACTORY_HEALTH_TYPES.WIP) {
          setShowTable(response.targets.isEIFileUploadedForWIP);
          setIsTargetInSyncWithAIP(response.targets.isTargetWIPInSyncWithAIP);
        }
        console.log(
          'isEIFileUploadedForThroughput',
          isEIFileUploadedForThroughput,
          isEIFileUploadedForWIP
        );

        return response;
      },
      onError: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_WARNING_TOAST,
          payload: { message: MESSAGE_STRINGS['Toast.message.WARNING'] },
        });
      },
      refetchOnWindowFocus: false,
      enabled: Boolean(entityId),
      retry: false,
    }
  );

  const { isFetching: isDownloadUrlFetching, refetch: fetchDownloadUrl } =
    useQuery(
      [QUERY_CONSTANTS.GET_EXPORT_URL, factoryHealthType],
      async () => {
        return await getPresignedExportUrl(
          entityId,
          getUtilityName(factoryHealthType)
        );
      },
      {
        onSuccess: (response) => {
          const status = convertCase(response.targets.status);
          setAccordionStatus(status);
          simulateUrlClick(response.data, FILE_CONTENT_TYPES.XLSX);
          return response;
        },
        onError: () => {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_WARNING_TOAST,
            payload: { message: MESSAGE_STRINGS['Toast.message.WARNING'] },
          });
        },
        refetchOnWindowFocus: false,
        retry: false,
        enabled: false,
      }
    );

  const {
    isLoading: isValidateTargetsLoading,
    isError: isValidateTargetsError,
    mutate: validateTargets,
    reset: resetValidateTargets,
    isSuccess: isValidationSuccess,
  } = useMutation(
    [QUERY_CONSTANTS.VALIDATE_UPLOADED_FILE, factoryHealthType],
    async (fileName) => {
      const params = {
        entityId: entityId,
        utility: getUtilityName(factoryHealthType),
        fileName,
      };
      const result = validateUpload(params);
      return result;
    },
    {
      onSuccess: (validateResponse) => {
        const accordionStatus = convertCase(
          validateResponse.targetStatus.status
        );
        setIsEIFileUploadedForWIP(
          validateResponse.targetStatus.isEIFileUploadedForWIP
        );
        setIsEIFileUploadedForThroughput(
          validateResponse.targetStatus.isEIFileUploadedForThroughput
        );
        setAccordionStatus(accordionStatus);
        if (factoryHealthType === FACTORY_HEALTH_TYPES.THROUGHPUT) {
          setIsTargetInSyncWithAIP(
            validateResponse.targetStatus.isTargetThroughputInSyncWithAIP
          );
          setIsTargetInSyncWithSchedule(
            !(
              validateResponse?.targets
                ?.isTargetThroughputInSyncWithSchedule === false
            )
          );
        } else if (factoryHealthType === FACTORY_HEALTH_TYPES.WIP) {
          setIsTargetInSyncWithAIP(
            validateResponse.targetStatus.isTargetWIPInSyncWithAIP
          );
        }
        fetchConfigStatuses();
        setIsNewFileUploaded(true);
        setShowTable(true);
        removeExisitingFileData(fileWithMeta);
      },
      onError: (error) => {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const listOfErrors = error.response.data.message.split('\n');
          setAccordionStatus(convertCase(ACCORDION_STATUS.IN_PROGRESS));
          setErrorList(listOfErrors);
          setIsUploadError(true);
        }
      },
      enabled: Boolean(entityId),
      retry: false,
    }
  );

  const backdropLoader =
    isDownloadUrlFetching || isTargetsStatusLoading || isTargetsStatusFetching;

  const isAlertForTargets = isAlertMessageShown(
    factoryHealthType,
    isTargetInSyncWithAIP,
    isTargetInSyncWithSchedule
  );
  return (
    <>
      <Accordian
        id={FACTORY_HEALTH_ID.FACTORY_HEALTH_TARGETS}
        expanded={isExpanded}
        onChange={() => {
          onExpandUpdate(FACTORY_HEALTH_ACCORDIONS.TARGETS);
        }}
      >
        <Accordian.AccordionSummary
          aria-controls="targets-content"
          id="targets-content"
          expandIcon={<DownArrow alt="down arrow" height={9} width={16} />}
          isAlert={isRespectiveFileUploaded && isAlertForTargets}
        >
          <Box
            sx={{ display: 'flex', alignItems: 'center' }}
            flexGrow={1}
            whiteSpace="nowrap"
          >
            <AccTitleTypography
              data-testid={TEST_IDS.THROUGHPUT_ACCORDION_HEADER}
            >
              {factoryHealthType === FACTORY_HEALTH_TYPES.THROUGHPUT
                ? MESSAGE_STRINGS['TARGETS_THROUGHPUT_ACCORDIAN_HEADER']
                : MESSAGE_STRINGS['TARGETS_WIP_ACCORDIAN_HEADER']}
            </AccTitleTypography>
            {isRespectiveFileUploaded && isAlertForTargets && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '1.5rem',
                  width:
                    factoryHealthType === FACTORY_HEALTH_TYPES.THROUGHPUT
                      ? '70%'
                      : '85%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    marginRight: '0.625rem',
                    height: '1.5rem',
                    width: '1.5rem',
                  }}
                >
                  <ExclamationMark
                    height="1.5rem"
                    width="1.5rem"
                    alt="exclamation mark"
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    wordWrap: 'break-word',
                    whiteSpace: 'normal',
                  }}
                >
                  <AlertText data-testid="fh-target-alert-message">
                    {getAlertMessage(
                      isTargetInSyncWithAIP,
                      isTargetInSyncWithSchedule
                    )}
                  </AlertText>
                </Box>
              </Box>
            )}
          </Box>
          <AccordionStatus status={accordionStatus} />
        </Accordian.AccordionSummary>
        <Accordian.AccordionDetails>
          {(!isRespectiveFileUploaded ||
            !isEIFileUploadedWithValidPurposeTags) && (
            <InitialUploader
              fetchDownloadUrl={fetchDownloadUrl}
              errorList={errorList}
              isUploadError={isUploadError}
              setIsUploadError={setIsUploadError}
              isValidateTargetsError={isValidateTargetsError}
              validateTargets={validateTargets}
              resetValidateTargets={resetValidateTargets}
              isValidateTargetsLoading={isValidateTargetsLoading}
              updateAccordionStatus={setAccordionStatus}
              entityId={entityId}
              isActionsDisabled={!isEIFileUploadedWithValidPurposeTags}
              selectedFactoryHealthType={selectedFactoryHealthType}
            />
          )}
          {isRespectiveFileUploaded && isEIFileUploadedWithValidPurposeTags && (
            <ReuploaderAndHierarchyTable
              fetchDownloadUrl={fetchDownloadUrl}
              validateTargets={validateTargets}
              errorList={errorList}
              isUploadError={isUploadError}
              setIsUploadError={setIsUploadError}
              resetValidateTargets={resetValidateTargets}
              isValidateTargetsLoading={isValidateTargetsLoading}
              isValidationSuccess={isValidationSuccess}
              isNewFileUploaded={isNewFileUploaded}
              setIsNewFileUploaded={setIsNewFileUploaded}
              showTable={showTable}
              setShowTable={setShowTable}
              ref={dropZoneRef}
              onSecondaryUpload={onSecondaryUpload}
              setFileWithMeta={setFileWithMeta}
              removeExisitingFileData={removeExisitingFileData}
              updateAccordionStatus={setAccordionStatus}
              entityId={entityId}
            />
          )}
        </Accordian.AccordionDetails>
        <Box
          display="flex"
          maxHeight="100%"
          flex={1}
          flexDirection="column"
          data-testid={'targets-loader'}
        >
          <CustomBackdrop open={backdropLoader}>
            <LoadingIndicator />
          </CustomBackdrop>
        </Box>
      </Accordian>
    </>
  );
};

Targets.propTypes = {
  onExpandUpdate: PropTypes.func,
  isExpanded: PropTypes.bool,
  fetchConfigStatuses: PropTypes.func,
};
export default Targets;
