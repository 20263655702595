import { List, styled } from '@mui/material';
import {
  useRef,
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
} from 'react';
import FactoryHealthTypeContext from '../../../context/factoryHealthContext';
import PropTypes from 'prop-types';
import useScrollSpy from '../../../utils/hooks/useScrollSpy';
import ListPanel from '../../../components/PanelTreeTable/ListPanel';
import PanelListItem from '../../../components/PanelTreeTable/ListPanel/PanelListItem';
import TreePanel from '../../../components/PanelTreeTable/TreePanel';
import WarningIcon from '../../../assets/img/warningIconV2.svg';
import LoadingIndicator from '../../../components/LoadingIndicator';

import HierarchyTableLabel from './HierarchyTableLabel';
import HeaderComponent from './HeaderComponent';

function getTreeNodes(data, depthLimit = 1) {
  const result = [];

  function helper(nodes, resultArr, depth) {
    if (depth > depthLimit) {
      return resultArr;
    }
    resultArr.push({
      entityId: nodes?.entityId,
      id: nodes?.entityId,
    });

    if (Array.isArray(nodes?.entityChildren)) {
      nodes?.entityChildren.forEach((node) =>
        helper(node, resultArr, depth + 1)
      );
    }
    return resultArr;
  }
  return helper(data, result, 0);
}

// Handing only For Y axis, with custom el support.
function waitForScrollEnd(el) {
  let lastChangedFrame = 0;
  let lastY = el.getBoundingClientRect().y;
  return new Promise((resolve) => {
    function tick(frames) {
      if (frames >= 500 || frames - lastChangedFrame > 20) {
        resolve();
      } else {
        if (el.getBoundingClientRect().y !== lastY) {
          lastChangedFrame = frames;
          lastY = el.getBoundingClientRect().y;
        }
        requestAnimationFrame(tick.bind(null, frames + 1));
      }
    }
    tick(0);
  });
}

const AllPanelContainer = styled('div')(() => ({
  display: 'flex',
  height: '40vh',
  width: '100%',
}));

const PanelContainer = styled('div')(({ theme }) => ({
  width: '16%',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    display: 'none',
    width: '0%',
  },
}));

const TreePanelContainer = styled('div')(({ theme }) => ({
  width: '68%',
  display: 'flex',
  backgroundColor: theme.palette.background.layoutBackgroundColor,
  [theme.breakpoints.down('md')]: {
    flex: 1,
  },
}));

const PanelTableContext = createContext();

export function usePanelTableContext() {
  return useContext(PanelTableContext);
}

// eslint-disable-next-line react/prop-types
function PanelTable({
  data = {},
  isLoading,
  treePanelHeader = null,
  headerKeys = [],
  nestingLevel = 2,
}) {
  const factoryHealthType = useContext(FactoryHealthTypeContext);
  const areas = useMemo(
    () =>
      data?.entityChildren?.map(
        ({ entityId, entityName, entityChildren, entityNumber }) => ({
          id: entityId,
          entityId,
          label: `${entityNumber}-${entityName}`,
          entityChildren,
        })
      ),
    [data]
  );

  const flattennedAreasAndLines = getTreeNodes(data, 2);

  function defaultLine() {
    const firstArea = data?.entityChildren[0];
    const firstLine = firstArea?.entityChildren?.[0]?.entityId;
    return [firstArea?.entityId, firstLine];
  }

  const [initialAreaId, initialLineId] = defaultLine();

  const [selectedAreaId, setSelectedAreaId] = useState(() => initialAreaId);
  const [selectedLineId, setSelectedLineId] = useState(() => initialLineId);
  const [selected, setSelected] = useState(() => [selectedLineId]);
  const [expanded, setExpanded] = useState(
    getTreeNodes(data, nestingLevel).map((da) => da.entityId)
  );

  const selectedAreaObj = areas.find((area) => area.id === selectedAreaId);

  const lines = selectedAreaObj?.entityChildren.map(
    ({ entityId, entityName, isDisabled, entityNumber }) => ({
      id: entityId,
      entityId,
      label: `${entityNumber}-${entityName}`,
      isDisabled,
      parentId: selectedAreaId,
    })
  );

  const treeRef = useRef();
  const autoScrolling = useRef(false);
  const treeWidth = treeRef?.current?.offsetWidth;

  function customScrollIntoViewById(id, headerOffset = 15) {
    const element = treeRef.current.querySelector(`#${CSS.escape(id)}`);
    if (!element) {
      return;
    }
    const elementPosition = element.offsetTop;
    const offsetPosition = elementPosition - headerOffset;

    autoScrolling.current = true;
    const mainDiv = treeRef?.current?.children?.[1];

    treeRef?.current?.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
    waitForScrollEnd(mainDiv).then(() => {
      autoScrolling.current = false;
    });
  }

  function handleOnAreaSelect(id) {
    return function onAreaSelect(doScroll = true) {
      setSelectedAreaId(id);
      const areaObj = areas.find((area) => area.id === id);
      const defaultSelectedId = areaObj?.entityChildren[0]?.entityId;
      setSelectedLineId(defaultSelectedId);
      setSelected([defaultSelectedId]);
      if (doScroll) {
        customScrollIntoViewById(defaultSelectedId, 48);
      }
    };
  }

  function handleOnLineSelect(id) {
    return function onLineSelect(doScroll = true) {
      setSelectedLineId(id);
      setSelected([id]);
      if (doScroll) {
        customScrollIntoViewById(id);
      }
    };
  }

  const handleSelect = (event, nodeIds) => {
    const [id, area, line, zone] = nodeIds.split('|');
    const areaId = `${id}|${area}`;
    if (zone != null || line != null) {
      // do nothing
    } else if (area != null) {
      setExpanded((expandedNodes) => [...expandedNodes, areaId]);
    } else {
      // prevent main plant from closing
      setExpanded((expandedNodes) => [...expandedNodes, id]);
    }
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const active = useScrollSpy({
    items: flattennedAreasAndLines,
    target: treeRef.current,
    offsetAdj: 0,
  });

  useEffect(() => {
    setExpanded(getTreeNodes(data, nestingLevel).map((da) => da.entityId));
  }, [factoryHealthType, nestingLevel, data]);

  useEffect(() => {
    if (treeRef.current) {
      handleOnLineSelect(selectedLineId)(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (active !== null && !autoScrolling.current) {
      const [id, area, line] = active.split('|');
      if (!area && !line) {
        setSelectedAreaId(initialAreaId);
        setSelectedLineId(initialLineId);
        return;
      }
      if (area && !line) {
        setSelectedAreaId(active);
        const currentArea = areas.find((ar) => ar.entityId === active);
        const currentLine = currentArea?.entityChildren?.[0]?.entityId;
        if (currentLine) {
          setSelectedLineId(currentLine);
        }
        return;
      }
      if (!line) {
        return;
      }
      setSelectedAreaId(`${id}|${area}`);
      setSelectedLineId(`${id}|${area}|${line}`);
      setSelected([`${id}|${area}|${line}`]);
    }
  }, [active, areas, initialAreaId, initialLineId]);

  // TODO: Using factor of 1 to remove extra space at the end of scroll. Keep for future reference.
  // const dynamicHeightFactor = nestingLevel === 3 ? 1.8 : 1.2;
  const dynamicHeightFactor = 1;

  return (
    <PanelTableContext.Provider
      value={{
        headerKeys,
      }}
    >
      <AllPanelContainer>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <PanelContainer>
              <ListPanel heading="Areas" data-testid="area-list-panel">
                <List value={selectedAreaId} disablePadding>
                  {areas.map((area) => (
                    <PanelListItem
                      key={`${area.id}area`}
                      isSelected={selectedAreaId === area.id}
                      id={area.id}
                      label={area.label}
                      onClick={handleOnAreaSelect(area.id)}
                    />
                  ))}
                </List>
              </ListPanel>
            </PanelContainer>
            <PanelContainer>
              <ListPanel heading="Lines" data-testid="line-list-panel">
                <List value={selectedLineId} disablePadding>
                  {lines?.map((line) => (
                    <PanelListItem
                      key={`${line.id}line`}
                      isSelected={selectedLineId === line.id}
                      id={line.id}
                      label={line.label}
                      onClick={handleOnLineSelect(line.id)}
                      isUpdated={line.isDisabled}
                      showIcon={line.isDisabled && <WarningIcon height={16} />}
                    />
                  ))}
                </List>
              </ListPanel>
            </PanelContainer>
            <TreePanelContainer>
              <TreePanel
                data={data}
                data-testid="tree-list-panel"
                selectedNodes={[selectedLineId, selectedAreaId, 'plant-1']}
                selected={selected}
                handleSelect={handleSelect}
                expanded={expanded}
                handleToggle={handleToggle}
                dynamicHeightFactor={dynamicHeightFactor}
                ref={treeRef}
                treeWidth={treeWidth}
                header={<HeaderComponent headers={treePanelHeader} />}
                LabelComponent={HierarchyTableLabel}
              />
            </TreePanelContainer>
          </>
        )}
      </AllPanelContainer>
    </PanelTableContext.Provider>
  );
}

PanelTable.propTypes = {
  data: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    entityChildren: PropTypes.array,
  }),
  isLoading: PropTypes.bool,
  treePanelHeader: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      tooltip: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  headerKeys: PropTypes.arrayOf(PropTypes.string),
  nestingLevel: PropTypes.number,
};

export default PanelTable;
