import React, { useState, useEffect } from 'react';
import { Typography, Box, MenuItem, styled } from '@mui/material';
import SelectDropDown from '../../components/SelectDropdown';
import {
  FACTORY_HEALTH_TYPES,
  MESSAGE_STRINGS,
  FACTORY_HEALTH_ID,
  FACTORY_HEALTH_ACCORDIONS,
  QUERY_CONSTANTS,
} from '../../constants/FactoryHealthConstants';
import Actuals from '../Actuals';
import Targets from '../Targets';
import { FactoryHealthTypeProvider } from '../../context/factoryHealthContext';
import DeviationThreshold from '../DeviationThreshold';
import { getFactoryConfigStatuses } from '../../utils/apiHelper';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import { useQuery } from 'react-query';
import { updateRxjsState } from '../../utils/rxjsStateNext';

const PageSubHeading = styled(Box)(() => ({
  margin: '1.5em 1.313em',
}));

const FactoryHealthConfigContainer = styled(Box)(() => ({
  height: '100%',
}));

const SubConfigContainer = styled(Box)(({ theme }) => ({
  margin: '0 0.875em',
  border: `0.313em solid ${theme.palette.background.layoutBackgroundColor}`,
  borderRadius: '4px 4px 0px 0px',
  height: '100%',
}));

const SubConfig = styled(Box)(() => ({
  marginBottom: '0.313em',
}));

const initialSelectedAccordion = [
  { type: FACTORY_HEALTH_ACCORDIONS.ACTUALS, expanded: true },
  { type: FACTORY_HEALTH_ACCORDIONS.TARGETS, expanded: false },
  { type: FACTORY_HEALTH_ACCORDIONS.DEVIATION_THRESHOLD, expanded: false },
];

const FactoryHealthConfig = () => {
  const [selectedFactoryHealthType, setSelectedFactoryHealthType] = useState(
    FACTORY_HEALTH_TYPES.THROUGHPUT
  );
  const [selectedAccordion, setSelectedAccordion] = useState(
    initialSelectedAccordion
  );
  const { rxjsState } = useRxjsState();
  const [entityId, setEntityId] = useState(rxjsState.plantId);

  useEffect(() => {
    if (rxjsState.plantId) {
      setEntityId(rxjsState.plantId);
    }
  }, [rxjsState.plantId]);

  useEffect(() => {
    setSelectedAccordion(initialSelectedAccordion);
  }, [selectedFactoryHealthType]);

  const handleFactoryHealthSelection = (event) => {
    const selectedFactoryHealthType = event.target.value;
    setSelectedFactoryHealthType(selectedFactoryHealthType);
  };
  const onExpandUpdate = (expandedAccordion) => {
    setSelectedAccordion((prevAccordion) =>
      prevAccordion.map((item) => ({
        ...item,
        expanded: item.type === expandedAccordion,
      }))
    );
  };
  const accordionIsExpanded = (accordion) => {
    return selectedAccordion.find((item) => item.type === accordion).expanded;
  };

  const { refetch: fetchConfigStatuses } = useQuery(
    [QUERY_CONSTANTS.GET_CONFIG_STATUSES, entityId],
    async () => {
      const response = await getFactoryConfigStatuses(entityId);
      return response;
    },
    {
      onSuccess: (response) => {
        const { factoryHealth } = response;
        updateRxjsState({
          configStatus: { ...rxjsState.configStatus, factoryHealth },
        });
      },
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return (
    <FactoryHealthConfigContainer
      display="flex"
      maxHeight="100%"
      flex={1}
      flexDirection="column"
    >
      <PageSubHeading display="flex" alignItems="center" marginBottom="1rem">
        <Box marginRight={1}>
          <Typography variant="h3" id="factoryLabel">
            Factory Health
          </Typography>
        </Box>
        <Box minWidth={150} marginLeft={2}>
          <SelectDropDown
            data-testid={FACTORY_HEALTH_ID.FACTORY_HEALTH_SELECTION}
            value={selectedFactoryHealthType}
            onChange={handleFactoryHealthSelection}
          >
            <MenuItem
              data-testid={FACTORY_HEALTH_ID.FACTORY_HEALTH_ITEMS}
              value={FACTORY_HEALTH_TYPES.WIP}
            >
              <Typography
                sx={{
                  color: (theme) => theme.customColors.white,
                }}
              >
                {MESSAGE_STRINGS['FACTORY_HEALTH.type.wip']}
              </Typography>
            </MenuItem>
            <MenuItem
              data-testid={FACTORY_HEALTH_ID.FACTORY_HEALTH_ITEMS}
              value={FACTORY_HEALTH_TYPES.THROUGHPUT}
            >
              <Typography
                sx={{
                  color: (theme) => theme.customColors.white,
                }}
              >
                {MESSAGE_STRINGS['FACTORY_HEALTH.type.throughput']}
              </Typography>
            </MenuItem>
          </SelectDropDown>
        </Box>
      </PageSubHeading>

      <FactoryHealthTypeProvider value={selectedFactoryHealthType}>
        <SubConfigContainer
          bgcolor="background.layoutBackgroundColor"
          display="flex"
          flex={1}
          flexDirection="column"
          flexBasis="100%"
        >
          <SubConfig>
            <Actuals
              onExpandUpdate={onExpandUpdate}
              isExpanded={accordionIsExpanded(
                FACTORY_HEALTH_ACCORDIONS.ACTUALS
              )}
              fetchConfigStatuses={fetchConfigStatuses}
            />
          </SubConfig>
          <SubConfig>
            <Targets
              onExpandUpdate={onExpandUpdate}
              isExpanded={accordionIsExpanded(
                FACTORY_HEALTH_ACCORDIONS.TARGETS
              )}
              fetchConfigStatuses={fetchConfigStatuses}
              selectedFactoryHealthType={selectedFactoryHealthType}
            />
          </SubConfig>
          <SubConfig>
            <DeviationThreshold
              onExpandUpdate={onExpandUpdate}
              isExpanded={accordionIsExpanded(
                FACTORY_HEALTH_ACCORDIONS.DEVIATION_THRESHOLD
              )}
              fetchConfigStatuses={fetchConfigStatuses}
            />
          </SubConfig>
        </SubConfigContainer>
      </FactoryHealthTypeProvider>
    </FactoryHealthConfigContainer>
  );
};

export default FactoryHealthConfig;
