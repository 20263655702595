import { ThemeProvider } from '@mui/material';
import { QueryClientProvider, QueryClient } from 'react-query';
import { theme } from '@smf/ui-util-app';
import { ToastProvider } from './context/toastContext';
import App from './containers/Routes';

export default function Root() {
  const queryClient = new QueryClient();
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <App />
        </ToastProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
