import { useState, useEffect, useRef, useCallback } from 'react';
import useThrottledOnScroll from '../useThrottledOnScroll';

// https://github.com/mui-org/material-ui/issues/16359#issuecomment-617365879
// material-ui still doesn't have a native implementation of scroll spy.

const getItemsClient = (items) =>
  items.map(({ id: hash }) => ({ hash, node: document.getElementById(hash) }));

const useScrollSpy = ({ items = [], target = window, offsetAdj } = {}) => {
  const itemsWithNodeRef = useRef([]);
  useEffect(() => {
    itemsWithNodeRef.current = getItemsClient(items);
  }, [items]);

  const [activeState, setActiveState] = useState(null);

  const findActiveIndex = useCallback(() => {
    let active;
    for (let i = itemsWithNodeRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      // if (document.documentElement.scrollTop < 200) {
      // if (target.scrollTop < 0) {
      // 	console.log(target);
      // 	console.log(target.scrollTop);
      // 	active = { hash: null };
      // 	break;
      // }

      const item = itemsWithNodeRef.current[i];

      if (process.env.NODE_ENV !== 'production') {
        if (!item.node) {
          // console.error(`Missing node on the item ${JSON.stringify(item, null, 2)}`);
        }
      }

      if (
        item.node &&
        item.node.offsetTop + offsetAdj < target.scrollTop + 21
      ) {
        active = item;
        break;
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash);
    }
  }, [activeState, offsetAdj, target.scrollTop]);

  useThrottledOnScroll(items.length > 0 ? findActiveIndex : null, 500, target);

  return activeState;
};

export default useScrollSpy;
