import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import LoadingIndicator from '../../../components/LoadingIndicator';
import {
  FACTORY_HEALTH_TYPES,
  MESSAGE_STRINGS,
  QUERY_CONSTANTS,
  TOAST_REDUCER_CONSTANTS,
} from '../../../constants/FactoryHealthConstants';
import FactoryHealthTypeContext from '../../../context/factoryHealthContext';
import { useToastContext } from '../../../context/toastContext';
import CustomBackdrop from '../../../styledComponents/CustomBackdrop';
import { getTargetsValueHierarchy } from '../../../utils/apiHelper';
import { getUtilityName } from '../../../utils/helpers';
import PanelTable from '../HierarchyTable';
import PropTypes from 'prop-types';

const TargetsTable = ({
  isValidationSuccess,
  entityId,
  isNewFileUploaded,
  setIsNewFileUploaded,
}) => {
  const factoryHealthType = useContext(FactoryHealthTypeContext);
  const [tableHierarchyData, setTableHierarchyData] = useState(null);
  const { toastDispatch } = useToastContext();

  const {
    isFetching: isTargetsValueHierarchyFetching,
    isLoading: isTargetsValueHierarchyLoading,
    refetch: refetchTargetsValueHierarchy,
  } = useQuery(
    [QUERY_CONSTANTS.GET_TARGETS_VALUE_HIERARCHY, factoryHealthType],
    async () => {
      return await getTargetsValueHierarchy(
        entityId,
        getUtilityName(factoryHealthType)
      );
    },
    {
      onSuccess: (response) => {
        setTableHierarchyData(response);
        if (isNewFileUploaded) {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
            payload: { message: MESSAGE_STRINGS['Targets.upload.SUCCESS'] },
          });
        }
        setIsNewFileUploaded(false);
        return response;
      },
      onError: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_WARNING_TOAST,
          payload: { message: MESSAGE_STRINGS['Toast.message.WARNING'] },
        });
      },
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
  useEffect(() => {
    if (isValidationSuccess) {
      refetchTargetsValueHierarchy();
    }
  }, [isValidationSuccess]);

  const backdropLoader =
    isTargetsValueHierarchyFetching || isTargetsValueHierarchyLoading;
  return (
    <>
      {tableHierarchyData && (
        <PanelTable
          isLoading={false}
          data={tableHierarchyData}
          headerKeys={[
            'value',
            ...(factoryHealthType === FACTORY_HEALTH_TYPES.THROUGHPUT
              ? ['maxDemonstrated', 'maxTheoretical']
              : []),
            'UoM',
          ]}
          nestingLevel={factoryHealthType === FACTORY_HEALTH_TYPES.WIP ? 5 : 3}
          treePanelHeader={[
            {
              heading:
                MESSAGE_STRINGS[
                  'Factory_HEALTH.tree.header.equipmentHierarchy'
                ],
            },
            {
              heading:
                MESSAGE_STRINGS[
                  `FACTORY_HEALTH.tree.header.${factoryHealthType}.header`
                ],
              tooltip:
                MESSAGE_STRINGS[
                  `FACTORY_HEALTH.tree.header.${factoryHealthType}.tooltip`
                ],
            },
            ...(factoryHealthType === FACTORY_HEALTH_TYPES.THROUGHPUT
              ? [
                  {
                    heading:
                      MESSAGE_STRINGS[
                        `FACTORY_HEALTH.tree.header.${factoryHealthType}.maxDemonstratedThroughput`
                      ],
                    tooltip:
                      MESSAGE_STRINGS[
                        `FACTORY_HEALTH.tree.header.${factoryHealthType}.maxDemonstratedThroughput.tooltip`
                      ],
                  },
                  {
                    heading:
                      MESSAGE_STRINGS[
                        `FACTORY_HEALTH.tree.header.${factoryHealthType}.maxTheoreticalThroughput`
                      ],
                    tooltip:
                      MESSAGE_STRINGS[
                        `FACTORY_HEALTH.tree.header.${factoryHealthType}.maxTheoreticalThroughput.tooltip`
                      ],
                  },
                ]
              : []),
            {
              heading: MESSAGE_STRINGS['Factory_HEALTH.tree.header.uom'],
            },
          ]}
        />
      )}
      <Box display="flex" maxHeight="100%" flex={1} flexDirection="column">
        <CustomBackdrop
          open={backdropLoader}
          // classes={{ root: backdropClasses.backdrop }}
        >
          <LoadingIndicator />
        </CustomBackdrop>
      </Box>
    </>
  );
};

TargetsTable.propTypes = {
  isValidationSuccess: PropTypes.bool,
  entityId: PropTypes.string,
  isNewFileUploaded: PropTypes.bool,
  setIsNewFileUploaded: PropTypes.func,
};

export default TargetsTable;
