import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import FactoryHealthTypeContext from '../../context/factoryHealthContext';
import {
  ACCORDION_STATUS,
  FACTORY_HEALTH_ACCORDIONS,
  FACTORY_HEALTH_ID,
  MESSAGE_STRINGS,
  QUERY_CONSTANTS,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants/FactoryHealthConstants';
import Accordian from '../../components/Accordion/index';
import infoIcon from '../../assets/img/infoIcon.svg?url';
import DownArrow from '../../assets/img/downArrow.svg';
import CButton from '../../components/Button';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import { Box, Typography, styled } from '@mui/material';
import AccordionStatus from '../../components/AccordionStatus';
import LoadingIndicator from '../../components/LoadingIndicator';
import DeviationSlider from '../../components/Slider';
import './DeviationThreshold.css';
import { useMutation, useQuery } from 'react-query';
import {
  getDeviationThresholds,
  updateDeviationThresholds,
} from '../../utils/apiHelper';
import { convertCase, getUtilityName } from '../../utils/helpers';
import { useToastContext } from '../../context/toastContext';
import AccTitleTypography from '../../styledComponents/AccTitleTypography';
import CustomTooltip from '../../styledComponents/CustomTooltip';
import CustomBackdrop from '../../styledComponents/CustomBackdrop';

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: '3.5rem',
  background: theme.palette.background.darkishBlackBlue,
  borderTop: `0.063em solid ${theme.palette.background.darkGrey}`,
  borderBottom: `0.063em solid ${theme.palette.background.darkGrey}`,
  alignItems: 'center',
}));

const MetricBox = styled(Box)(() => ({
  width: '20%',
  paddingLeft: '1.25rem',
}));

const DeviationBox = styled(Box)(() => ({
  width: '20%',
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontWeight: '400',
  color: theme.palette.text.lightUnitGrey,
}));

const MetricTypography = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  fontWeight: '400',
  lineHeight: '1.25rem',
}));

const PercentTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '0.75rem',
  fontWeight: '700',
  lineHeight: '1.02rem',
  color: theme.palette.text.primary,
}));

const ColorBoxTypography = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'weight',
})(({ weight, theme }) => {
  let fontWeight = '700';
  if (weight === '400') {
    fontWeight = '400';
  }
  return {
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
    fontWeight: fontWeight,
    lineHeight: '1.192rem',
    color: theme.palette.text.primary,
  };
});

const SliderLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  fontWeight: '400',
  lineHeight: '1.19rem',
  color: theme.palette.text.primary,
  height: '1.188rem',
}));

const DeviationRangeBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  columnGap: '0.31rem',
  padding: '0.5rem',
  background: theme.customColors.blackishGrey,
  border: `1px solid ${theme.customColors.wrapperGrey}`,
  borderRadius: '0.25rem',
}));

const ColorBlock = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})(({ color }) => {
  let bgColor = '#FFFFFF';
  if (color === '#DA291C') {
    bgColor = '#DA291C';
  } else if (color === '#ED8B00') {
    bgColor = '#ED8B00';
  } else if (color === '#44B02A') {
    bgColor = '#44B02A';
  }
  return {
    width: '0.3rem',
    height: '0.875rem',
    margin: 'auto 0px',
    background: bgColor,
  };
});

const FooterBox = styled(Box)(({ theme }) => ({
  height: '3.5rem',
  display: 'flex',
  flexDirection: 'row-reverse',
  borderTop: `0.063em solid ${theme.palette.background.darkGrey}`,
}));

const DeviationThreshold = ({
  onExpandUpdate = () => null,
  isExpanded = false,
  fetchConfigStatuses,
}) => {
  const [accordionStatus, setAccordionStatus] = useState(
    ACCORDION_STATUS.NOT_STARTED
  );
  const { rxjsState } = useRxjsState();
  const [entityId, setEntityId] = useState(rxjsState.plantId);

  useEffect(() => {
    if (rxjsState.plantId) {
      setEntityId(rxjsState.plantId);
    }
  }, [rxjsState.plantId]);

  const factoryHealthType = useContext(FactoryHealthTypeContext);
  const [colors, setColors] = useState([]);
  const [isSliderDisabled, setIsSliderDisabled] = useState(true);
  const [redColorCodeValues, setRedColorCodeValues] = useState(['NaN', 'NaN']);
  const [amberColorCodeValues, setAmberColorCodeValues] = useState([
    'NaN',
    'NaN',
  ]);
  const [greenColorCodeValues, setGreenColorCodeValues] = useState([
    'NaN',
    'NaN',
  ]);
  const { toastDispatch } = useToastContext();

  useEffect(() => {
    setAccordionStatus(ACCORDION_STATUS.NOT_STARTED);
    setColors([]);
    setIsSliderDisabled(true);
    setRedColorCodeValues(['NaN', 'NaN']);
    setGreenColorCodeValues(['NaN', 'NaN']);
    setAmberColorCodeValues(['NaN', 'NaN']);
  }, [factoryHealthType]);

  const {
    isLoading: isDeviationThresholdsLoading,
    isFetching: isDeviationThresholdsFetching,
  } = useQuery(
    [QUERY_CONSTANTS.GET_DEVIATION_THRESHOLDS, entityId, factoryHealthType],
    async () => {
      const deviationsResponse = await getDeviationThresholds(
        entityId,
        getUtilityName(factoryHealthType)
      );
      return deviationsResponse;
    },
    {
      onSuccess: (deviationsResponse) => {
        const status = convertCase(deviationsResponse.status);
        if (status !== 'Not Started') {
          setIsSliderDisabled(false);
        }
        setAccordionStatus(status);
        const deviationsList = deviationsResponse.deviations;

        let colorResponseList = [];
        for (let i = 0; i < deviationsList.length; i++) {
          colorResponseList.push(deviationsList[i].color);
          let limits = [];
          limits.push(deviationsList[i].lowerBound);
          if (deviationsList[i].label === 'AMBER') {
            limits.push(deviationsList[i].upperBound + 1);
          } else {
            limits.push(deviationsList[i].upperBound);
          }
          if (deviationsList[i].label === 'RED') {
            setRedColorCodeValues(limits);
            limits = [];
          } else if (deviationsList[i].label === 'AMBER') {
            setAmberColorCodeValues(limits);
            limits = [];
          } else if (deviationsList[i].label === 'GREEN') {
            setGreenColorCodeValues(limits);
            limits = [];
          }
        }
        setColors(colorResponseList);
      },
      retry: false,
      enabled: Boolean(entityId),
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: deviationsMutate, isLoading: isDeviationsUpdateLoading } =
    useMutation(
      [
        QUERY_CONSTANTS.UPDATE_DEVIATION_THRESHOLDS,
        entityId,
        factoryHealthType,
      ],
      async (reqBody) => {
        const sucessMessage = await updateDeviationThresholds(reqBody);
        return sucessMessage;
      },
      {
        onSuccess: (deviationsResponse) => {
          const status = convertCase(deviationsResponse.status);
          setAccordionStatus(status);
          fetchConfigStatuses();
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
            payload: { message: MESSAGE_STRINGS['Toast.message.SUCCESS'] },
          });
        },
        onError: () => {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            payload: { message: MESSAGE_STRINGS['Toast.message.ERROR'] },
          });
        },
        enabled: Boolean(entityId),
      }
    );

  const handleSave = () => {
    const reqBody = {
      entityId: entityId,
      utility: getUtilityName(factoryHealthType),
      itemsToBeUpdated: [
        {
          label: 'RED',
          lowerBound: redColorCodeValues[0],
          upperBound: redColorCodeValues[1],
        },
        {
          label: 'AMBER',
          lowerBound: amberColorCodeValues[0],
          upperBound: amberColorCodeValues[1] - 1,
        },
        {
          label: 'GREEN',
          lowerBound: greenColorCodeValues[0],
          upperBound: greenColorCodeValues[1],
        },
      ],
    };
    deviationsMutate(reqBody);
  };
  const backdropLoader =
    isDeviationThresholdsLoading ||
    isDeviationThresholdsFetching ||
    isDeviationsUpdateLoading;

  return (
    <Accordian
      id={FACTORY_HEALTH_ID.FACTORY_HEALTH_DEVIATION_THRESHOLD}
      expanded={isExpanded}
      onChange={() => {
        onExpandUpdate(FACTORY_HEALTH_ACCORDIONS.DEVIATION_THRESHOLD);
      }}
    >
      <Accordian.AccordionSummary
        aria-controls="=deviations-content"
        id="deviation-threshold-content"
        expandIcon={<DownArrow alt="down arrow" height={9} width={16} />}
      >
        <Box
          sx={{ flexGrow: 1, whiteSpace: 'nowrap' }}
          className="fhc-accordion-summary-container"
        >
          <AccTitleTypography variant="h5">
            {MESSAGE_STRINGS['DEVIATION_THRESHOLD_ACCORDION_HEADER']}
          </AccTitleTypography>
          <CustomTooltip
            title={MESSAGE_STRINGS['DEVIATION.info']}
            arrow
            placement="top-start"
          >
            {/* <InfoIcon alt="info icon" className="fhc-deviation-info-icon" /> */}
            <img
              src={infoIcon}
              alt="info icon"
              className="fhc-deviation-info-icon"
            />
          </CustomTooltip>
        </Box>
        <AccordionStatus status={accordionStatus} />
      </Accordian.AccordionSummary>
      <Accordian.AccordionDetails>
        <Box sx={{ width: '100%' }}>
          <HeaderBox>
            <MetricBox>
              <CustomTypography>
                {MESSAGE_STRINGS['CONFIG_HEADER.Metric']}
              </CustomTypography>
            </MetricBox>
            <DeviationBox>
              <CustomTypography>
                {MESSAGE_STRINGS['CONFIG_HEADER.Deviation_from_target']}
              </CustomTypography>
            </DeviationBox>
          </HeaderBox>
          <Box className="fhc-content">
            <Box className="fhc-row1">
              <Box className="fhc-row1-child1 ">
                <MetricTypography>Throughput</MetricTypography>
              </Box>
              <Box className="fhc-row1-child2">
                <Box className="fhc-slider-container">
                  <Box className="fhc-left-percent-box">
                    <PercentTypography>-100%</PercentTypography>
                  </Box>
                  <Box
                    className="fhc-slider-box"
                    onClick={() => setIsSliderDisabled(false)}
                  >
                    <DeviationSlider
                      setPoints={amberColorCodeValues}
                      updateSetPoints={setAmberColorCodeValues}
                      updateRedColorCodeValues={setRedColorCodeValues}
                      updateGreenColorCodeValues={setGreenColorCodeValues}
                      isSliderDisabled={isSliderDisabled}
                      updateAccordionStatus={setAccordionStatus}
                    />
                  </Box>
                  <Box className="fhc-right-percent-box">
                    <PercentTypography>0%</PercentTypography>
                  </Box>
                  <Box className="fhc-slider-label-box">
                    <SliderLabel>Target</SliderLabel>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="fhc-row2">
              <Box className="fhc-row2-child">
                <Box className="fhc-color-box-container ">
                  <DeviationRangeBox>
                    <ColorBlock color={colors[0]} />
                    <ColorBoxTypography>
                      {`${redColorCodeValues[0]}%`}
                    </ColorBoxTypography>
                    <ColorBoxTypography weight={'400'}>to</ColorBoxTypography>
                    <ColorBoxTypography>
                      {`${redColorCodeValues[1]}%`}
                    </ColorBoxTypography>
                  </DeviationRangeBox>
                  <DeviationRangeBox>
                    <ColorBlock color={colors[1]} />
                    <ColorBoxTypography>
                      {`${amberColorCodeValues[0]}%`}
                    </ColorBoxTypography>
                    <ColorBoxTypography weight={'400'}>to</ColorBoxTypography>
                    <ColorBoxTypography>
                      {`${amberColorCodeValues[1] - 1}%`}
                    </ColorBoxTypography>
                  </DeviationRangeBox>
                  <DeviationRangeBox>
                    <ColorBlock color={colors[2]} />
                    <ColorBoxTypography>
                      {`${greenColorCodeValues[0]}%`}
                    </ColorBoxTypography>
                    <ColorBoxTypography weight={'400'}>to</ColorBoxTypography>
                    <ColorBoxTypography>
                      {`${greenColorCodeValues[1]}%`}
                    </ColorBoxTypography>
                  </DeviationRangeBox>
                </Box>
              </Box>
            </Box>
          </Box>
          <FooterBox>
            <Box className="fhc-btn-box">
              <CButton
                text="Save"
                disabled={false}
                onClick={handleSave}
                data-testid={'deviation-save'}
              />
            </Box>
          </FooterBox>
        </Box>
      </Accordian.AccordionDetails>
      <Box sx={{ display: 'flex', maxHeight: '100%', flexDirection: 'column' }}>
        <CustomBackdrop open={backdropLoader}>
          <LoadingIndicator />
        </CustomBackdrop>
      </Box>
    </Accordian>
  );
};

export default DeviationThreshold;

DeviationThreshold.propTypes = {
  onExpandUpdate: PropTypes.func,
  isExpanded: PropTypes.bool,
  fetchConfigStatuses: PropTypes.func,
};
