import { TableRow, styled } from '@mui/material';

const CustomRow = styled(TableRow)(({ theme }) => ({
  borderTop: `0.063em solid ${theme.palette.border.darkGrey}`,
  borderBottom: `0.063em solid ${theme.palette.border.darkGrey}`,
  height: '3em',
  width: '100%',
  paddingLeft: '1em',
}));

export default CustomRow;
