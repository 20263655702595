import { styled, Box } from '@mui/material';
import React, { useContext } from 'react';
import DownArrow from '../../../../assets/img/hierarchyDownArrow.svg';
import PropTypes from 'prop-types';
import { usePanelTableContext } from '..';
import {
  ENTITY_TYPES,
  FACTORY_HEALTH_TYPES,
} from '../../../../constants/FactoryHealthConstants';
import FactoryHealthTypeContext from '../../../../context/factoryHealthContext';
import DynamicTooltipHeader from '../../../../components/DynamicTooltip';
import HierarchyLabelTypography from '../../../../styledComponents/HierarchyLabelTypgoraphy';
import { getHierarchyLabel } from '../../../../utils/helpers';

const LabelContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'factoryHealthType',
})(({ factoryHealthType }) => {
  let gridTemplate =
    factoryHealthType === FACTORY_HEALTH_TYPES.THROUGHPUT
      ? '28.06% 16.15% 23.73% 23.28% 8.75%'
      : '50% 25% 25%';
  return {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: gridTemplate,
  };
});

const HierarchyLabelContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'depth',
})(({ depth }) => {
  if (depth === 0) {
    depth = 0.5;
  } else if (depth === 4) {
    depth = 6;
  }
  return {
    display: 'flex',
    flex: 1,
    minWidth: 0,
    paddingLeft: `${depth}rem`,
  };
});

const HierarchyTableLabel = ({
  entityName,
  targetValue = {},
  isHeadingDisabled,
  entityNumber,
  entityType,
  entityChildren = [],
  depth = 0,
  nodes,
}) => {
  const hasChildren = entityChildren != null && entityChildren.length > 0;
  const { headerKeys = [] } = usePanelTableContext();
  const noIcon = [ENTITY_TYPES.PLANT, ENTITY_TYPES.AREA, ENTITY_TYPES.CELL];
  const factoryHealthType = useContext(FactoryHealthTypeContext);

  return (
    <LabelContainer factoryHealthType={factoryHealthType}>
      <HierarchyLabelContainer depth={depth}>
        {!noIcon.includes(nodes?.entityType) && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '0.75rem',
            }}
          >
            {hasChildren && <DownArrow />}
          </Box>
        )}

        <DynamicTooltipHeader
          heading={getHierarchyLabel(entityType, entityName, entityNumber)}
          isHeader={false}
          entityType={entityType}
          isHeadingDisabled={isHeadingDisabled}
          hasChildren={hasChildren}
        />
      </HierarchyLabelContainer>
      {headerKeys.map((key) => (
        <Box key={key} sx={{ paddingLeft: '3px' }}>
          <HierarchyLabelTypography
            noWrap
            entityType={entityType}
            hasChildren={hasChildren}
          >
            {targetValue[key] != null
              ? key === 'UoM'
                ? targetValue[key].toLowerCase()
                : targetValue[key]
              : '-'}
          </HierarchyLabelTypography>
        </Box>
      ))}
    </LabelContainer>
  );
};

HierarchyTableLabel.propTypes = {
  entityName: PropTypes.string,
  entityNumber: PropTypes.string,
  targetValue: PropTypes.shape({
    value: PropTypes.number,
  }),
  treeWidth: PropTypes.number,
  depth: PropTypes.number,
  nodes: PropTypes.shape({
    entityId: PropTypes.string,
    entityType: PropTypes.string,
    isDisabled: PropTypes.bool,
    entityName: PropTypes.string,
    type: PropTypes.string,
    entityChildren: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isHeadingDisabled: PropTypes.bool,
  entityType: PropTypes.string,
  entityChildren: PropTypes.arrayOf(PropTypes.shape({})),
};

export default HierarchyTableLabel;
