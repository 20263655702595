import { styled } from '@mui/material';
import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { MESSAGE_STRINGS } from '../../constants/FactoryHealthConstants';

const CustomTooltip = styled(
  ({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
  { shouldForwardProp: (prop) => prop != 'heading' }
)(({ theme, heading }) => {
  let width = '';
  if (
    heading === MESSAGE_STRINGS['FACTORY_HEALTH.tree.header.THROUGHPUT.header']
  ) {
    width = '16.875rem';
  } else if (
    heading ===
    MESSAGE_STRINGS[
      'FACTORY_HEALTH.tree.header.THROUGHPUT.maxDemonstratedThroughput'
    ]
  ) {
    width = '19.375rem';
  } else if (
    heading ===
    MESSAGE_STRINGS[
      'FACTORY_HEALTH.tree.header.THROUGHPUT.maxTheoreticalThroughput'
    ]
  ) {
    width = '19.188rem';
  }
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      maxWidth: '31.25rem',
      color: theme.palette.text.lightYellowishGray,
      backgroundColor: theme.palette.background.eerieBlack,
      boxShadow: `0.25rem 0.25rem 0.25rem ${theme.customColors.tooltipShadow}`,
      border: `1px solid ${theme.customColors.metalGrey}`,
      borderRadius: '0.25rem',
      height: heading ? '5.25rem' : '',
      width: width,
      padding: '0.625rem 0.75rem',
      marginTop: '0.25rem !important',
    },
  };
});

export default CustomTooltip;
