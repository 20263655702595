import React, { useState, useEffect } from 'react';
import { Slider, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { ACCORDION_STATUS } from '../../constants/FactoryHealthConstants';

const CustomSlider = styled(Slider, {
  shouldForwardProp: (props) => props !== 'leftPercent',
})(({ theme, leftPercent }) => ({
  '& .MuiSlider-root': {
    top: '0.13rem',
    width: '100%',
  },
  '& .MuiSlider-thumb': {
    background: theme.palette.background.default,
    height: '1.125rem',
    width: '1.125rem',
  },
  '& .MuiSlider-thumb.Mui-disabled': {
    width: '1.125rem',
    height: '1.125rem',
    marginTop: '-1px',
  },
  '& .MuiSlider-mark': {
    background: theme.palette.background.default,
  },
  '& .MuiSlider-rail': {
    background: `linear-gradient(to right, #DA291C 0% ${leftPercent}%, #43B02A ${leftPercent}% 100%);`,
    height: 8,
    opacity: 1,
    borderRadius: '0.5rem',
  },
  '& .MuiSlider-track': {
    background: theme.palette.background.tangerineColor,
    height: 8,
  },
  '& .MuiSlider-valueLabel': {
    fontFamily: 'Open Sans',
    fontSize: '0.874rem',
    fontWeight: '700',
    lineHeight: '1.188rem',
    background: theme.palette.background.blackGrey,
    '&>*': {
      background: theme.palette.background.blackGrey,
    },
  },
}));

const TooltipContainer = styled('div')(() => ({
  width: '100%',
  top: '0.13rem',
  '& .MuiSlider-thumb.Mui-disabled': {
    width: '1.125rem',
    height: '1.125rem',
    // marginTop: '-1px',
  },
}));
const DeviationSlider = ({
  setPoints,
  updateSetPoints,
  updateRedColorCodeValues,
  updateGreenColorCodeValues,
  isSliderDisabled,
  updateAccordionStatus,
}) => {
  const calculateLeftPercent = (num) => {
    return 100 - Math.abs(num);
  };
  const [leftPercent, setLeftPercent] = useState();

  function sliderValue(value) {
    return `${value}%`;
  }

  useEffect(() => {
    const leftPercent = calculateLeftPercent(setPoints[0]);
    setLeftPercent(leftPercent);
  }, [setPoints]);

  const onChange = (e, value) => {
    updateAccordionStatus(ACCORDION_STATUS.IN_PROGRESS);
    const [min, max] = value;

    if (
      Math.abs(max) !== Math.abs(min) &&
      Math.abs(min) - Math.abs(max) >= 1 &&
      min !== -100 &&
      max !== 0
    ) {
      updateSetPoints(value);
      setLeftPercent(calculateLeftPercent(value[0]));
      updateRedColorCodeValues([-100, min - 1]);
      updateGreenColorCodeValues([max, 0]);
    }
  };

  return (
    <TooltipContainer>
      <CustomSlider
        valueLabelDisplay="auto"
        value={setPoints}
        step={1}
        min={-100}
        max={0}
        onChange={onChange}
        disabled={isSliderDisabled}
        leftPercent={leftPercent}
        valueLabelFormat={sliderValue}
      />
    </TooltipContainer>
  );
};

export default DeviationSlider;

DeviationSlider.propTypes = {
  setPoints: PropTypes.array,
  updateSetPoints: PropTypes.func,
  updateGreenColorCodeValues: PropTypes.func,
  updateRedColorCodeValues: PropTypes.func,
  isSliderDisabled: PropTypes.bool,
  updateAccordionStatus: PropTypes.func,
};
