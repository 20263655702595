import { Box, List, ListItem, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import {
  MESSAGE_STRINGS,
  TEST_IDS,
} from '../../../constants/FactoryHealthConstants';
import GeneralButton from '../../GeneralButton';

const MainModalContainer = styled(Box)(({ theme }) => ({
  width: '37.5rem',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0.5rem',
  backgroundColor: theme.customColors.blackGrey,
}));

const ModalTitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  borderBottom: `0.0125px solid ${theme.palette.border.wrapperGrey}`,
  height: '4rem',
}));

const ModalTitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 700,
  lineHeight: '1.688rem',
  color: theme.customColors.white,
  padding: '1.125rem 0rem 1.188rem 1.5rem',
}));

const ModalBodyContainer = styled(Box)(() => ({
  padding: '1.5rem 3rem 1rem 1.5rem',
  overflow: 'auto',
  maxHeight: '32rem',
}));

const AlertModalBodyTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Open Sans',
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.1875rem',
  color: theme.customColors.white,
}));

const ModalButtonContainer = styled(Box)(() => ({
  height: '4.75rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  alignSelf: 'flex-end',
}));

const StyledListItem = styled(ListItem)(() => ({
  paddingLeft: '0.2em',
  fontSize: '0.875rem ',
  fontWeight: '400',
  fontFamily: 'Open Sans',
  lineHeight: '1.192rem ',
}));

const StyledList = styled(List)(() => ({
  margin: 'auto 0',
  padding: '0 ',
}));

const MultipleErrorTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isBold',
})(({ isBold = false }) => ({
  fontWeight: isBold ? '700' : '400',
  lineHeight: '1.192rem',
  fontSize: '0.875rem',
}));
const CustomGeneralButton = styled(GeneralButton)(() => ({
  margin: '0rem 1.5rem 0rem 0.15rem',
  width: '7.5rem',
}));
const ErrorListModal = ({ errorList = [], setShowMultipleErrorModal }) => {
  return (
    <MainModalContainer data-testid={TEST_IDS.ERROR_MODAL}>
      <ModalTitleContainer>
        <ModalTitleTypography>
          {MESSAGE_STRINGS['Targets.upload.ErrorList.title']}
        </ModalTitleTypography>
      </ModalTitleContainer>

      <ModalBodyContainer>
        <AlertModalBodyTypography>
          <StyledList>
            {errorList.map((errMsg, idx) => {
              let errorListItems;
              let isErrorHeading = false;
              let isTwoColumnsError = false;
              if (errMsg.includes(':')) {
                errorListItems = errMsg.split(':');
                if (errorListItems.length === 3) isTwoColumnsError = true;
                isErrorHeading = true;
              } else if (errMsg.includes('!')) {
                errorListItems = errMsg.split('!');
                isErrorHeading = true;
              } else {
                errorListItems = [errMsg];
              }
              return (
                <StyledListItem
                  key={idx}
                  data-testid={TEST_IDS.ERROR_MODAL_LIST_ITEM}
                >
                  <Box sx={{ display: 'flex' }}>
                    <MultipleErrorTypography component="span" isBold={true}>
                      {`${idx + 1}. `}
                    </MultipleErrorTypography>
                    <Box sx={{ marginLeft: '0.75rem' }}>
                      {isErrorHeading && (
                        <MultipleErrorTypography component="span" isBold={true}>
                          {errorListItems[0] === 'Invalid Input'
                            ? `${errorListItems[0]}:`
                            : `${errorListItems[0]}!`}
                        </MultipleErrorTypography>
                      )}
                      {isErrorHeading && !isTwoColumnsError && (
                        <>
                          <MultipleErrorTypography component="span">
                            {errorListItems[1]}
                          </MultipleErrorTypography>
                        </>
                      )}
                      {isErrorHeading && isTwoColumnsError && (
                        <MultipleErrorTypography component="span">
                          {`${errorListItems[1]}: ${errorListItems[2]}`}
                        </MultipleErrorTypography>
                      )}
                      {!isErrorHeading && (
                        <MultipleErrorTypography component="span">
                          {errorListItems[0]}
                        </MultipleErrorTypography>
                      )}
                    </Box>
                  </Box>
                </StyledListItem>
              );
            })}
          </StyledList>
        </AlertModalBodyTypography>
      </ModalBodyContainer>

      <ModalButtonContainer>
        <CustomGeneralButton
          data-testid={TEST_IDS.ERROR_MODAL_BTN}
          text={MESSAGE_STRINGS['Targets.upload.ErrorList.okay']}
          onClick={() => setShowMultipleErrorModal(false)}
          weight={'600'}
          lineHeight={'1rem'}
          size={'0.875rem'}
        />
      </ModalButtonContainer>
    </MainModalContainer>
  );
};

export default ErrorListModal;

ErrorListModal.propTypes = {
  errorList: PropTypes.array,
  setShowMultipleErrorModal: PropTypes.func,
};
