import { useState, useEffect, useContext } from 'react';
import FactoryHealthTypeContext from '../../context/factoryHealthContext';
import { useQuery, useMutation } from 'react-query';
import PropTypes from 'prop-types';
import Accordian from '../../components/Accordion/index';
import AccordionStatus from '../../components/AccordionStatus';
import {
  FACTORY_HEALTH_ACCORDIONS,
  FACTORY_HEALTH_TYPES,
  MESSAGE_STRINGS,
  ACTUALS_ID,
  ACTUALS_CONSTANTS,
  FACTORY_HEALTH_ID,
  DEFAULT_UOM,
  QUERY_CONSTANTS,
  TOAST_REDUCER_CONSTANTS,
  ACCORDION_STATUS,
} from '../../constants/FactoryHealthConstants';
import CustomCell from '../../styledComponents/CustomCell';
import HeaderRow from '../../styledComponents/HeaderRow';
import CustomTd from '../../styledComponents/CustomTd';
import CustomRow from '../../styledComponents/CustomRow';
import CustomTableHead from '../../styledComponents/CustomTableHead';
import CustomBackdrop from '../../styledComponents/CustomBackdrop';
import DownArrow from '../../assets/img/downArrow.svg';
import CButton from '../../components/Button';
import CTextField from '../../components/TextField';
import SelectDropdown from '../../components/SelectDropdown';
import '../../utils/commonStyles/commonStyles.css';
import {
  getUtilityName,
  validateActualsRefreshRate,
  validateActualsEmptyFields,
  convertCase,
} from '../../utils/helpers';
import ExclamationMark from '../../assets/img/exclamationMark.svg';
import { getActuals, updateActuals } from '../../utils/apiHelper';
import { useToastContext } from '../../context/toastContext';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useRxjsState } from '../../utils/hooks/useRxjsState';
import {
  Box,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import AccTitleTypography from '../../styledComponents/AccTitleTypography';

const CustomTypography = styled(Typography)(() => ({
  fontSize: '0.875rem ',
  fontWeight: 400,
}));

const Actuals = ({
  onExpandUpdate = () => null,
  isExpanded = false,
  fetchConfigStatuses,
}) => {
  const factoryHealthType = useContext(FactoryHealthTypeContext);
  const [selectedTimeRange, setSelectedTimeRange] = useState(
    ACTUALS_CONSTANTS.DEFAULT_DROPDOWN_VALUE
  );
  const [selectedRefreshRateUnit, setSelectedRefreshRateUnit] = useState(
    ACTUALS_CONSTANTS.DEFAULT_DROPDOWN_VALUE
  );
  const [refreshRateValue, setRefreshRateValue] = useState();
  const [UoM, setUoM] = useState(DEFAULT_UOM);
  const [refreshRateErrorMessage, setRefreshRateErrorMessage] = useState('');
  const [accordionStatus, setAccordionStatus] = useState(
    ACCORDION_STATUS.NOT_STARTED
  );
  const [actualsEmptyFields, setActualsEmptyFields] = useState([]);

  const { rxjsState } = useRxjsState();
  const [entityId, setEntityId] = useState(rxjsState.plantId);

  useEffect(() => {
    if (rxjsState.plantId) {
      setEntityId(rxjsState.plantId);
    }
  }, [rxjsState.plantId]);

  const { toastDispatch } = useToastContext();
  const handleTimeRangeSelection = (event) => {
    setAccordionStatus(ACCORDION_STATUS.IN_PROGRESS);
    setSelectedTimeRange(event.target.value);
    setActualsEmptyFields((prevActualsEmptyFields) => {
      return prevActualsEmptyFields.filter(
        (emptyField) => emptyField !== 'timeRange'
      );
    });
  };

  const handleRefreshRateUnitSelection = (event) => {
    setAccordionStatus(ACCORDION_STATUS.IN_PROGRESS);
    setRefreshRateErrorMessage(
      validateActualsRefreshRate(refreshRateValue, event.target.value)
    );
    setSelectedRefreshRateUnit(event.target.value);
    setActualsEmptyFields((prevActualsEmptyFields) => {
      return prevActualsEmptyFields.filter(
        (emptyField) => emptyField !== 'refreshRateUnit'
      );
    });
  };

  const handleRefreshRateValueChange = (event) => {
    setAccordionStatus(ACCORDION_STATUS.IN_PROGRESS);
    const newValue = event.target.value.replace(/\D/g, '');
    setRefreshRateErrorMessage(
      validateActualsRefreshRate(newValue, selectedRefreshRateUnit)
    );
    setRefreshRateValue(newValue);
    setActualsEmptyFields((prevActualsEmptyFields) => {
      return prevActualsEmptyFields.filter(
        (emptyField) => emptyField !== 'refreshRateValue'
      );
    });
  };

  useEffect(() => {
    setSelectedTimeRange(ACTUALS_CONSTANTS.DEFAULT_DROPDOWN_VALUE);
    setSelectedRefreshRateUnit(ACTUALS_CONSTANTS.DEFAULT_DROPDOWN_VALUE);
    setRefreshRateValue();
    setUoM(DEFAULT_UOM);
    setRefreshRateErrorMessage('');
    setAccordionStatus(ACCORDION_STATUS.NOT_STARTED);
    setActualsEmptyFields([]);
  }, [factoryHealthType]);

  const { isLoading: isGetActualsLoading, isFetching: isGetActualsFetching } =
    useQuery(
      [QUERY_CONSTANTS.GET_ACTUALS, factoryHealthType, entityId],
      async () => {
        setRefreshRateValue('');
        setSelectedRefreshRateUnit(ACTUALS_CONSTANTS.DEFAULT_DROPDOWN_VALUE);
        setSelectedTimeRange(ACTUALS_CONSTANTS.DEFAULT_DROPDOWN_VALUE);
        const actualsResponse = await getActuals(
          entityId,
          getUtilityName(factoryHealthType)
        );
        return actualsResponse;
      },
      {
        //On successful execution of api request
        onSuccess: (actualsResponse) => {
          const status = convertCase(actualsResponse.status);
          setAccordionStatus(status);
          if (factoryHealthType === FACTORY_HEALTH_TYPES.THROUGHPUT) {
            const timeRangeValue = actualsResponse.actuals.find(
              (item) => item.label === MESSAGE_STRINGS['ACTUALS_TIME_RANGE']
            ).value;
            const timeRangeChangedValue = actualsResponse.actuals.find(
              (item) => item.label === MESSAGE_STRINGS['ACTUALS_TIME_RANGE']
            ).changedValue;
            const timeRange = timeRangeChangedValue
              ? timeRangeChangedValue
              : timeRangeValue;
            if (timeRange) {
              setSelectedTimeRange(timeRange);
            }
          }
          const refreshRateValue = actualsResponse.actuals.find(
            (item) => item.label === MESSAGE_STRINGS['ACTUALS_REFRESH_RATE']
          ).value;
          const refreshRateUnit = actualsResponse.actuals.find(
            (item) => item.label === MESSAGE_STRINGS['ACTUALS_REFRESH_RATE']
          ).unit;
          if (refreshRateValue) {
            setRefreshRateValue(refreshRateValue.toString());
          }
          if (refreshRateUnit) {
            setSelectedRefreshRateUnit(refreshRateUnit);
          }
          const uom = actualsResponse.actuals.find(
            (item) => item.label === MESSAGE_STRINGS['ACTUALS_UOM']
          ).value;
          if (uom) {
            setUoM(convertCase(uom));
          }
        },
        retry: false,
        enabled: Boolean(entityId),
        refetchOnWindowFocus: false,
      }
    );

  const { mutate: actualsMutate, isLoading: isActualsUpdating } = useMutation(
    [QUERY_CONSTANTS.UPDATE_ACTUALS],
    async (reqBody) => {
      const sucessMessage = await updateActuals(reqBody);
      return sucessMessage;
    },
    {
      onSuccess: (actualsResponse) => {
        const status = convertCase(actualsResponse.status.actuals);
        setAccordionStatus(status);
        fetchConfigStatuses();
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
          payload: { message: MESSAGE_STRINGS['Toast.message.SUCCESS'] },
        });
      },
      onError: () => {
        toastDispatch({
          type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
          payload: { message: MESSAGE_STRINGS['Toast.message.ERROR'] },
        });
      },
      enabled: Boolean(entityId),
    }
  );

  async function handleOnSave() {
    const { emptyFields } = validateActualsEmptyFields.apply(
      this,
      factoryHealthType == FACTORY_HEALTH_TYPES.THROUGHPUT
        ? [refreshRateValue, selectedRefreshRateUnit, selectedTimeRange]
        : [refreshRateValue, selectedRefreshRateUnit]
    );

    if (emptyFields) {
      setActualsEmptyFields(emptyFields);
    }
    const reqBody = {
      utility: getUtilityName(factoryHealthType),
      entityId: entityId,
      refreshRate: refreshRateValue,
      refreshRateUnit: selectedRefreshRateUnit,
      ...(factoryHealthType === FACTORY_HEALTH_TYPES.THROUGHPUT && {
        timeRange: selectedTimeRange,
      }),
    };
    if (!emptyFields) {
      actualsMutate(reqBody);
    }
  }

  const backdropLoader =
    isGetActualsLoading || isGetActualsFetching || isActualsUpdating;
  return (
    <Accordian
      id={FACTORY_HEALTH_ID.FACTORY_HEALTH_ACTUALS}
      expanded={isExpanded}
      onChange={() => {
        onExpandUpdate(FACTORY_HEALTH_ACCORDIONS.ACTUALS);
      }}
    >
      <Accordian.AccordionSummary
        aria-controls="actuals-content"
        id="actuals-content"
        expandIcon={<DownArrow alt="down arrow" height={9} width={16} />}
      >
        <Box flexGrow={1} whiteSpace="nowrap">
          <AccTitleTypography variant="h5">
            {MESSAGE_STRINGS['ACTUALS_ACCORDIAN_HEADER']}
          </AccTitleTypography>
        </Box>
        <AccordionStatus status={accordionStatus} />
      </Accordian.AccordionSummary>
      <Accordian.AccordionDetails>
        <TableContainer>
          <Table aria-label="actual-content-table">
            <CustomTableHead>
              <HeaderRow>
                <CustomTd className={'fhc-tablecell'}>
                  <div className="fhc-parameter-flexbox">
                    <div className="fhc-parameter-flexbox-value">
                      <CustomTypography>
                        {MESSAGE_STRINGS['CONFIG_HEADER.Parameter']}
                      </CustomTypography>
                    </div>
                  </div>
                </CustomTd>
                <CustomTd className={'fhc-tablecell'}>
                  <div className="fhc-value-flexbox">
                    <div className="fhc-value-flexbox-value">
                      <CustomTypography>
                        {MESSAGE_STRINGS['CONFIG_HEADER.Value']}
                      </CustomTypography>
                    </div>
                  </div>
                </CustomTd>
              </HeaderRow>
            </CustomTableHead>
            <TableBody>
              <CustomRow>
                <td className="fhc-tablecell">
                  <div className="fhc-parameter-flexbox">
                    <div className="fhc-parameter-flexbox-value">
                      <CustomTypography>
                        {MESSAGE_STRINGS['CONFIG_HEADER.UoM']}
                      </CustomTypography>
                    </div>
                  </div>
                </td>
                <td className="fhc-tablecell">
                  <div className="fhc-value-flexbox">
                    <div className="fhc-value-flexbox-value">
                      <CustomTypography>{UoM}</CustomTypography>
                    </div>
                  </div>
                </td>
              </CustomRow>
              {factoryHealthType === FACTORY_HEALTH_TYPES.THROUGHPUT && (
                <CustomRow>
                  <td className="fhc-tablecell">
                    <div>
                      <div className="fhc-parameter-flexbox">
                        <div className="fhc-parameter-flexbox-value">
                          <CustomTypography>
                            {MESSAGE_STRINGS['ACTUALS_TIME_RANGE']}
                          </CustomTypography>
                        </div>
                      </div>
                    </div>
                    {actualsEmptyFields.includes('timeRange') && (
                      <div className="fhc-value-flexbox fhc-value-error-box"></div>
                    )}
                  </td>
                  <td className="fhc-tablecell">
                    <div className="fhc-value-flexbox">
                      <div className="fhc-value-flexbox-value">
                        <SelectDropdown
                          data-testid={ACTUALS_ID.TIME_RANGE_SELECTION}
                          value={selectedTimeRange}
                          placeholderValue={
                            ACTUALS_CONSTANTS.DEFAULT_DROPDOWN_VALUE
                          }
                          error={actualsEmptyFields.includes('timeRange')}
                          onChange={handleTimeRangeSelection}
                        >
                          <MenuItem
                            data-testid={ACTUALS_ID.TIME_RANGE_ITEMS}
                            value={ACTUALS_CONSTANTS.TIME_RANGE_SHIFT}
                          >
                            <CustomTypography
                              sx={{
                                color: (theme) => theme.customColors.white,
                              }}
                            >
                              {MESSAGE_STRINGS['THROUGHPUT_TIME_RANGE.shift']}
                            </CustomTypography>
                          </MenuItem>
                          <MenuItem
                            data-testid={ACTUALS_ID.TIME_RANGE_ITEMS}
                            value={ACTUALS_CONSTANTS.TIME_RANG_PRODUCTION_DAY}
                          >
                            <CustomTypography
                              sx={{
                                color: (theme) => theme.customColors.white,
                              }}
                            >
                              {
                                MESSAGE_STRINGS[
                                  'THROUGHPUT_TIME_RANGE.production_day'
                                ]
                              }
                            </CustomTypography>
                          </MenuItem>
                        </SelectDropdown>
                      </div>
                    </div>
                    {actualsEmptyFields.includes('timeRange') && (
                      <div className="fhc-value-flexbox fhc-value-error-box">
                        <div className="fhc-error-message-container">
                          <ExclamationMark
                            alt="exclamation mark"
                            className="fhc-error-icon"
                          />
                          <Typography className="fhc-error-text">
                            {'Required Field'}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </td>
                </CustomRow>
              )}
              <CustomRow>
                <td className="fhc-tablecell">
                  <div>
                    <div className="fhc-parameter-flexbox">
                      <div className="fhc-parameter-flexbox-value">
                        <CustomTypography>
                          {MESSAGE_STRINGS['ACTUALS_REFRESH_RATE']}
                        </CustomTypography>
                      </div>
                    </div>
                  </div>
                  {(Boolean(refreshRateErrorMessage) ||
                    actualsEmptyFields.includes('refreshRateValue') ||
                    actualsEmptyFields.includes('refreshRateUnit')) && (
                    <div className="fhc-value-flexbox fhc-value-error-box"></div>
                  )}
                </td>
                <td className="fhc-tablecell">
                  <div className="fhc-value-flexbox">
                    <CustomCell
                      className={'fhc-value-flexbox-value fhc-text-cell'}
                    >
                      <CTextField
                        data-testid={ACTUALS_ID.REFRESH_RATE_TEXTFIELD}
                        value={refreshRateValue}
                        error={
                          Boolean(refreshRateErrorMessage) ||
                          actualsEmptyFields.includes('refreshRateValue')
                        }
                        onChange={handleRefreshRateValueChange}
                        placeholder="-"
                      />
                    </CustomCell>
                    <div className="fhc-value-flexbox-value">
                      <SelectDropdown
                        data-testid={ACTUALS_ID.REFRESH_RATE_UNIT_SELECTION}
                        value={selectedRefreshRateUnit}
                        placeholderValue={
                          ACTUALS_CONSTANTS.DEFAULT_DROPDOWN_VALUE
                        }
                        error={actualsEmptyFields.includes('refreshRateUnit')}
                        onChange={handleRefreshRateUnitSelection}
                      >
                        <MenuItem
                          data-testid={ACTUALS_ID.REFERSH_RATE_UNIT_ITEMS}
                          value={ACTUALS_CONSTANTS.REFRESH_RATE_UNIT_SECONDS}
                        >
                          <CustomTypography
                            sx={{
                              color: (theme) => theme.customColors.white,
                            }}
                          >
                            {
                              MESSAGE_STRINGS[
                                'ACTUALS_REFRESH_RATE_UNIT.seconds'
                              ]
                            }
                          </CustomTypography>
                        </MenuItem>
                        <MenuItem
                          data-testid={ACTUALS_ID.REFERSH_RATE_UNIT_ITEMS}
                          value={ACTUALS_CONSTANTS.REFRESH_RATE_UNIT_MINUTES}
                        >
                          <CustomTypography
                            sx={{
                              color: (theme) => theme.customColors.white,
                            }}
                          >
                            {
                              MESSAGE_STRINGS[
                                'ACTUALS_REFRESH_RATE_UNIT.minutes'
                              ]
                            }
                          </CustomTypography>
                        </MenuItem>
                        <MenuItem
                          data-testid={ACTUALS_ID.REFERSH_RATE_UNIT_ITEMS}
                          value={ACTUALS_CONSTANTS.REFRESH_RATE_UNIT_HOURS}
                        >
                          <CustomTypography
                            sx={{
                              color: (theme) => theme.customColors.white,
                            }}
                          >
                            {MESSAGE_STRINGS['ACTUALS_REFRESH_RATE_UNIT.hours']}
                          </CustomTypography>
                        </MenuItem>
                      </SelectDropdown>
                    </div>
                  </div>
                  {Boolean(refreshRateErrorMessage) && (
                    <div className="fhc-value-flexbox">
                      <div className="fhc-error-message-container">
                        <ExclamationMark
                          alt="exclamation mark"
                          className="fhc-error-icon"
                        />
                        <Typography className="fhc-error-text">
                          {refreshRateErrorMessage}
                        </Typography>
                      </div>
                    </div>
                  )}

                  {(actualsEmptyFields.includes('refreshRateValue') ||
                    actualsEmptyFields.includes('refreshRateUnit')) && (
                    <div className="fhc-value-flexbox fhc-value-error-box">
                      {actualsEmptyFields.includes('refreshRateValue') && (
                        <div className="fhc-error-message-container">
                          <ExclamationMark
                            alt="exclamation mark"
                            className="fhc-error-icon"
                          />
                          <Typography className="fhc-error-text">
                            {'Required Field'}
                          </Typography>
                        </div>
                      )}

                      {actualsEmptyFields.includes('refreshRateUnit') && (
                        <div className="fhc-error-message-parent-container">
                          {!actualsEmptyFields.includes('refreshRateValue') && (
                            <div className="fhc-error-message-container"></div>
                          )}
                          <div className="fhc-error-message-container">
                            <ExclamationMark
                              alt="exclamation mark"
                              className="fhc-error-icon"
                            />
                            <Typography className="fhc-error-text">
                              {'Required Field'}
                            </Typography>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </td>
              </CustomRow>
              <TableRow>
                <td className="fhc-tablecell" />
                <td className="fhc-tablecell">
                  <div className="fhc-footer-row">
                    <div className="fhc-save-button">
                      <CButton
                        text="Save"
                        disabled={
                          Boolean(refreshRateErrorMessage) ||
                          actualsEmptyFields.length !== 0
                        }
                        onClick={handleOnSave}
                        data-testid={'test-id'}
                      />
                    </div>
                  </div>
                </td>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Accordian.AccordionDetails>
      <Box display="flex" maxHeight="100%" flex={1} flexDirection="column">
        <CustomBackdrop open={backdropLoader} data-testid={'actuals-loader'}>
          <LoadingIndicator />
        </CustomBackdrop>
      </Box>
    </Accordian>
  );
};
Actuals.propTypes = {
  onExpandUpdate: PropTypes.func,
  isExpanded: PropTypes.bool,
  fetchConfigStatuses: PropTypes.func,
};
export default Actuals;
