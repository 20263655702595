import React from 'react';
import {
  MESSAGE_STRINGS,
  TARGETS_LABELS,
} from '../../../constants/FactoryHealthConstants';
import InfoIcon from '../../../assets/img/infoIcon.svg?url';
import WhiteDownloadIcon from '../../../assets/img/whiteDownloadIcon.svg';
import WhiteUploadIcon from '../../../assets/img/whiteUploadIcon.svg';
import { Box, ButtonBase, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CustomTooltip from '../../../styledComponents/CustomTooltip';

const ExportUploadTypography = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  fontWeight: '400',
  marginLeft: '0.577rem',
}));

const Reuploader = ({ fetchDownloadUrl, onSecondaryUpload }) => {
  return (
    <Box width={'100%'}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
          height: '3.5rem',
        }}
      >
        <ButtonBase
          onClick={fetchDownloadUrl}
          data-testid={'download'}
          disableRipple
        >
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <WhiteDownloadIcon
              height="1.846rem"
              width="1.846rem"
              alt="download-icon"
            />
            <ExportUploadTypography>
              {TARGETS_LABELS.EXPORT_BUTTON}
            </ExportUploadTypography>
          </Box>
        </ButtonBase>
        <ButtonBase
          onClick={onSecondaryUpload}
          data-testid={'upload'}
          disableRipple
        >
          <Box
            sx={{
              display: 'flex',
              marginLeft: '1.1394rem',
            }}
          >
            <WhiteUploadIcon
              height="1.846rem"
              width="1.846rem"
              alt="upload-icon"
            />
            <ExportUploadTypography>
              {TARGETS_LABELS.UPLOAD_BUTTON}
            </ExportUploadTypography>
          </Box>
        </ButtonBase>
        <CustomTooltip
          title={MESSAGE_STRINGS['TARGETS_UPLOAD.message']}
          placement="top-end"
        >
          <img
            src={InfoIcon}
            alt="info icon"
            style={{
              margin: '0rem 1rem 0rem 0.5rem',
              width: '1rem',
              height: '1rem',
            }}
          />
        </CustomTooltip>
      </Box>
    </Box>
  );
};
Reuploader.propTypes = {
  fetchDownloadUrl: PropTypes.func,
  validateTargets: PropTypes.func,
  onSecondaryUpload: PropTypes.func,
};

export default Reuploader;
