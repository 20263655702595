import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { globalstate$ } from '@smf/ui-util-app';
import FactoryHealthConfig from '../FactoryHealthConfig';

/**
 * This is the React Route Configuration for
 * Factory Health Config Micro App.
 *
 * 1. '/config/factory_health'       --> Root Route for  Factory Health Config Micro App
 * 2. /{AnyOtherRoute}               --> takes the control to Cognito Login Page
 *
 * @author Krishnama Naidu
 *
 * @Return  React Router Config for Factory Health Config Micro App
 */

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path={'/config/factory_health'}
          element={
            globalstate$.value.isLoggedInUser ? (
              <FactoryHealthConfig />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
        <Route
          render={() => (
            <Route path="*" element={<Navigate to="/" replace />} />
          )}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
