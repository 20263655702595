import React from 'react';
import { Button as MuiButton, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
const BUTTON_TYPE = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};

const SaveButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'type' && prop !== 'buttonSize',
})(({ theme }) => ({
  color: `${theme.palette.text.secondary} `,
  border: `0.0625em solid ${theme.palette.background.saveGreen}`,
  boxSizing: 'border-box',
  borderRadius: '1.25rem',
  minWidth: '7.5em ',
  height: '100%',
  background: `${theme.palette.background.saveGreen} `,
  '&:hover': {
    color: `${theme.palette.text.primary} `,
    background: 'inherit',
  },
  '&:disabled': {
    color: `${theme.palette.text.lightYellowishGray} `,
    background: `${theme.palette.background.darkGrey} `,
    border: `0.0625em solid ${theme.palette.border.lightRaven}`,
  },
}));

const SecondaryButton = styled(MuiButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  border: `0.0625em solid ${theme.palette.text.primary}`,
  boxSizing: 'border-box',
  borderRadius: '1.25rem',
  minWidth: '7.5em',
  background: 'inherit',
  height: '100%',
  '&:hover': {
    color: theme.palette.text.secondary,
    background: theme.palette.text.primary,
  },
  '&:disabled': {
    color: theme.palette.text.lightYellowishGray,
    background: theme.palette.background.darkGrey,
    border: `0.0625em solid ${theme.palette.border.lightRaven}`,
  },
}));

const ButtonLabel = styled(Typography)(() => ({
  textTransform: 'none',
}));
function Button({
  type = BUTTON_TYPE.PRIMARY,
  text,
  size,
  color,
  variant,
  onClick,
  disable,
  children,
  ...other
}) {
  return type === BUTTON_TYPE.SECONDARY ? (
    <SecondaryButton
      variant={variant || 'contained'}
      size={size || 'medium'}
      color={color || 'primary'}
      onClick={onClick}
      disabled={disable || false}
      {...other}
    >
      {children || <ButtonLabel variant="subtitle1">{text}</ButtonLabel>}
    </SecondaryButton>
  ) : (
    <SaveButton
      variant={variant || 'contained'}
      size={size || 'medium'}
      color={color || 'primary'}
      onClick={onClick}
      disabled={disable || false}
      {...other}
    >
      {children || <ButtonLabel variant="subtitle1">{text}</ButtonLabel>}
    </SaveButton>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  disable: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Button;
