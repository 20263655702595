import { useState, useContext, useEffect } from 'react';
import FactoryHealthTypeContext from '../../context/factoryHealthContext';
import { Box, ButtonBase, Typography, Grid } from '@mui/material';
import Dropzone from 'react-dropzone-uploader';
import PropTypes from 'prop-types';
import {
  ACCORDION_STATUS,
  FACTORY_HEALTH_TYPES,
  FILE_ACCEPT_TYPES,
  FILE_CONTENT_TYPES,
  FILE_UPLOAD_STATUSES,
  MESSAGE_STRINGS,
} from '../../constants/FactoryHealthConstants';
import { convertCase, getUtilityName } from '../../utils/helpers';
import WhiteDownloadIcon from '../../assets/img/whiteDownloadIcon.svg';
import { getPresignedUploadUrl } from '../../utils/apiHelper';
import ErrorList from '../../components/ErrorList';
import CustomBackdrop from '../../styledComponents/CustomBackdrop';
import UploadInput from '../../components/UploadInput';
import UploadPreview from '../../components/UploadPreview';
import DashedBorder from '../../styledComponents/DashedBorder';
import ErrorListModal from '../../components/ErrorList/MultipleErrorModal';

const InitialUploader = ({
  fetchDownloadUrl,
  errorList,
  isUploadError,
  setIsUploadError,
  validateTargets,
  isValidateTargetsError,
  resetValidateTargets,
  isValidateTargetsLoading,
  updateAccordionStatus,
  entityId,
  isActionsDisabled,
  selectedFactoryHealthType,
}) => {
  const factoryHealthType = useContext(FactoryHealthTypeContext);
  const [isFilePreparing, setIsFilePreparing] = useState(false);
  const [prevFileData, setPrevFileData] = useState(null);
  const [showMultipleErrorModal, setShowMultipleErrorModal] = useState(false);
  const removeExisitingFileData = (fileWithMeta) => {
    if (fileWithMeta) {
      fileWithMeta.remove();
    }
  };
  const handleChangeStatus = (fileDetails, status) => {
    if (
      status === FILE_UPLOAD_STATUSES.PREPARING ||
      status === FILE_UPLOAD_STATUSES.GETTING_UPLOAD_PARAMS ||
      status === FILE_UPLOAD_STATUSES.UPLOADING
    ) {
      setIsFilePreparing(true);
      updateAccordionStatus(convertCase(ACCORDION_STATUS.IN_PROGRESS));
    }
    if (status === FILE_UPLOAD_STATUSES.DONE) {
      validateTargets(fileDetails.meta.name);
      setPrevFileData(fileDetails);
    }
  };
  useEffect(() => {
    removeExisitingFileData(prevFileData);
  }, [factoryHealthType]);
  const getUploadUrl = async (file) => {
    const params = {
      utility: getUtilityName(factoryHealthType),
      contentType: FILE_CONTENT_TYPES.XLSX,
      fileName: file,
      entityId: entityId,
    };
    const result = await getPresignedUploadUrl(params);
    return result.data;
  };
  const getUploadParams = async (params) => {
    const { file, meta } = params;
    const uploadUrl = await getUploadUrl(meta.name);
    return {
      body: file,
      url: uploadUrl,
      meta,
      method: 'PUT',
      headers: {
        'Content-Type': FILE_CONTENT_TYPES.XLSX,
      },
    };
  };
  const handleReupload = (fileWithMeta) => {
    fileWithMeta.remove();
    setIsFilePreparing(false);
    setIsUploadError(false);
    resetValidateTargets();
  };

  const backdropLoader =
    isValidateTargetsLoading || (isFilePreparing && !isValidateTargetsError);
  return (
    <Box sx={{ width: '100%' }}>
      <div className={'fhc-header-flexbox'}>
        <div className={'fhc-export-flexbox'}>
          <ButtonBase
            onClick={fetchDownloadUrl}
            disableRipple
            disabled={isActionsDisabled}
          >
            <WhiteDownloadIcon
              alt="download-icon"
              height="1.844rem"
              width="1.844rem"
            />
            <Typography className={'fhc-export-label'}>Export Data</Typography>
          </ButtonBase>
        </div>
      </div>
      {isUploadError && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1em',
          }}
        >
          <ErrorList
            errorList={errorList}
            isMultipleErrors={Boolean(errorList.length > 3)}
            setShowMultipleErrorModal={setShowMultipleErrorModal}
            sx={{ marginBottom: '1rem' }}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DashedBorder className={'fhc-upload-box'}>
          <Box sx={{ width: '100%' }}>
            <Dropzone
              getUploadParams={getUploadParams}
              onChangeStatus={handleChangeStatus}
              maxFiles={1}
              SubmitButtonComponent={() => null}
              PreviewComponent={(previewProps) => (
                <UploadPreview
                  previewComponentProps={previewProps}
                  handleReupload={handleReupload}
                  isValidateTargetsError={isValidateTargetsError}
                  isFilePreparing={isFilePreparing}
                />
              )}
              InputComponent={UploadInput}
              accept={FILE_ACCEPT_TYPES.XLSX}
              multiple={false}
              canRestart
              disabled={isActionsDisabled}
            />
            <Grid item>
              <Typography variant="h6" sx={{ textAlign: 'center' }}>
                {selectedFactoryHealthType === FACTORY_HEALTH_TYPES.THROUGHPUT
                  ? MESSAGE_STRINGS['THROUGHPUT.TARGETS.uploadXlsx']
                  : MESSAGE_STRINGS['WIP.TARGETS.uploadXlsx']}
              </Typography>
            </Grid>
          </Box>
        </DashedBorder>
      </Box>
      <Box display="flex" maxHeight="100%" flex={1} flexDirection="column">
        <CustomBackdrop open={backdropLoader || showMultipleErrorModal}>
          {showMultipleErrorModal && (
            <ErrorListModal
              errorList={errorList}
              setShowMultipleErrorModal={setShowMultipleErrorModal}
            />
          )}
        </CustomBackdrop>
      </Box>
    </Box>
  );
};
InitialUploader.displayName = 'InitialUploader';
InitialUploader.propTypes = {
  fetchDownloadUrl: PropTypes.func,
  isUploadError: PropTypes.bool,
  setIsUploadError: PropTypes.func,
  isValidateTargetsError: PropTypes.bool,
  errorList: PropTypes.array,
  validateTargets: PropTypes.func,
  resetValidateTargets: PropTypes.func,
  isValidateTargetsLoading: PropTypes.bool,
  updateAccordionStatus: PropTypes.func,
  entityId: PropTypes.string,
  isActionsDisabled: PropTypes.bool,
};

export default InitialUploader;
