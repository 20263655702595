import { styled, Typography } from '@mui/material';
import { ENTITY_TYPES } from '../../constants/FactoryHealthConstants';

const HierarchyLabelTypography = styled(Typography, {
  shouldForwardProp: (prop) =>
    prop !== 'isHeadingDisabled' &&
    prop !== 'entityType' &&
    prop !== 'hasChildren',
})(({ theme, isHeadingDisabled, entityType, hasChildren }) => ({
  fontWeight:
    entityType === ENTITY_TYPES.CELL || !hasChildren ? '400 ' : '700 ',
  alignItems: 'center ',
  lineHeight: '1.192rem',
  fontSize: '0.875rem',
  color: isHeadingDisabled ? theme.palette.text.darkGrey : undefined,
  textTransform: 'none',
}));

export default HierarchyLabelTypography;
