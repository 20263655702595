import { Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
const TooltipHeading = styled(Typography)(() => ({
  lineHeight: '1.021rem',
  fontSize: '0.75rem',
  fontWeight: 700,
}));

const TooltipContent = styled(Typography)(() => ({
  lineHeight: '1.021rem',
  fontSize: '0.75rem',
  fontWeight: 400,
}));

const TooltipDetails = ({ heading, content }) => {
  return (
    <TooltipContent>
      <TooltipHeading component="span">{heading}</TooltipHeading>
      {content}
    </TooltipContent>
  );
};

TooltipDetails.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.string,
};

export default TooltipDetails;
