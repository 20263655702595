import { React, useState, useEffect } from 'react';
import { Snackbar, Slide, Typography, Box, alpha, styled } from '@mui/material';
import PropTypes from 'prop-types';
import {
  TOAST_TYPE,
  MESSAGE_STRINGS,
} from '../../constants/FactoryHealthConstants';
import ErrorIcon from '../../assets/img/dangerIconV2.svg';
import WarningIcon from '../../assets/img/warningIconV2.svg';
import SuccessIcon from '../../assets/img/successIconV2.svg';
import InfoIcon from '../../assets/img/infoIconV2.svg';

const colorType = {
  [TOAST_TYPE.ERROR]: 'cocoaToneRed',
  [TOAST_TYPE.WARNING]: 'bronzeTone',
  [TOAST_TYPE.SUCCESS]: 'blackOliveGreen',
  [TOAST_TYPE.INFO]: 'nileBlue',
};

const borderColors = {
  [TOAST_TYPE.ERROR]: 'lavaRed',
  [TOAST_TYPE.WARNING]: 'tangerineColor',
  [TOAST_TYPE.SUCCESS]: 'saveGreen',
  [TOAST_TYPE.INFO]: 'highlightBlue',
};

const iconMap = {
  [TOAST_TYPE.ERROR]: <ErrorIcon height="2rem" width="2rem" alt="error icon" />,
  [TOAST_TYPE.WARNING]: (
    <WarningIcon height="2rem" width="2rem" alt="warning icon" />
  ),
  [TOAST_TYPE.SUCCESS]: (
    <SuccessIcon height="2rem" width="2rem" alt="success icon" />
  ),
  [TOAST_TYPE.INFO]: <InfoIcon height="2rem" width="2rem" alt="warning icon" />,
};

let timeout;

const StyledToast = styled(Snackbar, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'borderColor',
})(({ theme, color, borderColor }) => ({
  zIndex: `${theme.zIndex.modal + 1}`,
  '& .MuiSnackbarContent-root': {
    minWidth: '28.5rem',
    background: alpha(theme.palette.background[color], 0.95),
    border: `1px solid ${theme.palette.border[borderColor]}`,
    borderRadius: '0.35em',
    padding: 0,
  },
  '& .MuiSnackbarContent-message': {
    width: '100%',
    padding: 0,
  },
}));

const ToastTypography = styled(Typography)(() => ({
  lineHeight: '1.192rem',
  fontSize: '0.875rem',
  fontWeight: '400',
}));

function ShowSnackBar({ toastType = TOAST_TYPE.ERROR, message = '' }) {
  const toast_icon = iconMap[toastType];
  return (
    <Box
      sx={{
        height: '4rem',
        width: '28.5rem',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box marginLeft="1.688rem" marginTop="1rem" marginBottom="1rem">
        {toast_icon}
      </Box>
      <Box marginLeft="1.25rem">
        <ToastTypography>
          {message || MESSAGE_STRINGS[`Toast.message.${toastType}`]}
        </ToastTypography>
      </Box>
    </Box>
  );
}
function Toast({ type, message }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setOpen(false);
    }, 4000);
    setOpen(true);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const transition = (props) => <Slide {...props} direction="right" />;

  const vertical = 'bottom';
  const horizontal = 'center';
  return (
    <StyledToast
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      TransitionComponent={transition}
      message={<ShowSnackBar toastType={type} message={message} />}
      key={vertical + horizontal}
      color={colorType[type] || colorType[TOAST_TYPE.ERROR]}
      borderColor={borderColors[type] || borderColors[TOAST_TYPE.ERROR]}
    />
  );
}

ShowSnackBar.propTypes = {
  toastType: PropTypes.string,
  message: PropTypes.string,
};
Toast.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string,
};
export default Toast;
