import { alpha, Box, List, ListItem, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ExclamationMark from '../../assets/img/exclamationMark.svg';
import {
  MESSAGE_STRINGS,
  TEST_IDS,
} from '../../constants/FactoryHealthConstants';
const ErrorBorder = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'length',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  background: `${alpha(theme.palette.background.errorColor, 0.05)}`,
  borderRadius: '0.25rem',
  maxHeight: 'auto',
  border: `1px solid ${theme.palette.border.lavaRed}`,
}));

const StyledListItem = styled(ListItem)(() => ({
  fontSize: '0.875rem ',
  fontWeight: '400 ',
  fontFamily: 'Open Sans ',
  lineHeight: '1.188rem ',
  paddingRight: '0',
  paddingTop: '0.625rem',
  paddingLeft: '0rem',
}));

const StyledList = styled(List)(() => ({
  margin: 'auto 0',
  padding: '0 ',
}));

const StyledIcon = styled(ExclamationMark, {
  shouldForwardProp: (prop) => prop !== 'isMorethanOneError',
})(({ isMorethanOneError = false }) => ({
  margin: isMorethanOneError
    ? '0.625rem 1rem 1.063rem 1rem'
    : '1rem 1rem 1.063rem 1rem',
  height: '1.5rem',
  width: '1.5rem',
}));

const MultipleErrorTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isBold',
})(({ isBold = false }) => ({
  fontWeight: isBold ? '700' : '400',
  lineHeight: '1.192rem',
  fontSize: '0.875rem',
}));

const ErrorList = ({
  errorList,
  isMultipleErrors,
  setShowMultipleErrorModal,
}) => {
  const isSingleError = errorList.length === 1;
  return (
    <Box
      sx={{ width: '100%', padding: '0.625rem' }}
      data-testid={TEST_IDS.ERROR_LIST}
    >
      <ErrorBorder length={errorList.length}>
        <Box sx={{ alignSelf: 'self-start', height: '3.5rem' }}>
          <StyledIcon
            isMorethanOneError={errorList.length > 1 && errorList.length < 4}
          />
        </Box>

        {isMultipleErrors ? (
          <MultipleErrorTypography
            data-testid={TEST_IDS.MULTIPLE_ERROR_BTN}
            onClick={() => setShowMultipleErrorModal(true)}
          >
            <MultipleErrorTypography
              component="span"
              isBold={true}
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {`${MESSAGE_STRINGS['Targets.upload.error.text.prefix']} ${errorList.length}`}
            </MultipleErrorTypography>
            {MESSAGE_STRINGS['Targets.upload.error.text.suffix']}
          </MultipleErrorTypography>
        ) : (
          <StyledList>
            {errorList.map((errMsg, idx) => {
              let errorListItems;
              let isErrorHeading = false;
              let isTwoColumnsError = false;
              if (errMsg.includes(':')) {
                errorListItems = errMsg.split(':');
                if (errorListItems.length === 3) isTwoColumnsError = true;
                isErrorHeading = true;
              } else if (errMsg.includes('!')) {
                errorListItems = errMsg.split('!');
                isErrorHeading = true;
              } else {
                errorListItems = [errMsg];
              }
              return (
                <StyledListItem
                  key={idx}
                  data-testid={TEST_IDS.ERROR_LIST_ITEM}
                >
                  <Box sx={{ display: 'flex', paddingRight: '2rem' }}>
                    <MultipleErrorTypography component="span" isBold={true}>
                      {!isSingleError && `${idx + 1}. `}
                    </MultipleErrorTypography>
                    <Box
                      sx={{ marginLeft: !isSingleError ? '0.75rem' : '0rem' }}
                    >
                      {isErrorHeading && (
                        <MultipleErrorTypography component="span" isBold={true}>
                          {errorListItems[0] === 'Invalid Input'
                            ? `${errorListItems[0]}:`
                            : `${errorListItems[0]}!`}
                        </MultipleErrorTypography>
                      )}
                      {isErrorHeading && !isTwoColumnsError && (
                        <>
                          <MultipleErrorTypography component="span">
                            {errorListItems[1]}
                          </MultipleErrorTypography>
                        </>
                      )}
                      {isErrorHeading && isTwoColumnsError && (
                        <MultipleErrorTypography component="span">
                          {`${errorListItems[1]}: ${errorListItems[2]}`}
                        </MultipleErrorTypography>
                      )}
                      {!isErrorHeading && (
                        <MultipleErrorTypography component="span">
                          {errorListItems[0]}
                        </MultipleErrorTypography>
                      )}
                    </Box>
                  </Box>
                </StyledListItem>
              );
            })}
          </StyledList>
        )}
      </ErrorBorder>
    </Box>
  );
};
export default ErrorList;

ErrorList.propTypes = {
  errorList: PropTypes.array,
  isMultipleErrors: PropTypes.bool,
  setShowMultipleErrorModal: PropTypes.func,
};
