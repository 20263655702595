import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomTooltip from '../../styledComponents/CustomTooltip';
import HierarchyHeaderTypography from '../../styledComponents/HierarchyHeaderTypography';
import HierarchyLabelTypography from '../../styledComponents/HierarchyLabelTypgoraphy';

const DynamicTooltipHeader = ({
  heading = null,
  isHeader = true,
  entityType = null,
  isHeadingDisabled = false,
  hasChildren = false,
}) => {
  const typographyRef = useRef();
  const [hoverStatus, setHover] = useState(true);

  const compareSize = () => {
    const compare =
      typographyRef.current.scrollWidth <= typographyRef.current.clientWidth;
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, []);

  return (
    <CustomTooltip
      title={heading}
      placement="top-start"
      disableHoverListener={hoverStatus}
    >
      {isHeader ? (
        <HierarchyHeaderTypography
          ref={typographyRef}
          className="heading-container-heading"
        >
          {heading}
        </HierarchyHeaderTypography>
      ) : (
        <HierarchyLabelTypography
          ref={typographyRef}
          noWrap
          entityType={entityType}
          isHeadingDisabled={isHeadingDisabled}
          hasChildren={hasChildren}
        >
          {heading}
        </HierarchyLabelTypography>
      )}
    </CustomTooltip>
  );
};

DynamicTooltipHeader.propTypes = {
  heading: PropTypes.string,
  isHeader: PropTypes.bool,
  entityType: PropTypes.string,
  isHeadingDisabled: PropTypes.bool,
  hasChildren: PropTypes.bool,
};

export default DynamicTooltipHeader;
