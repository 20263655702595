import { styled } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import {
  createContext,
  useContext,
  forwardRef,
  useRef,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import RenderData from '../../../containers/Targets/HierarchyTable/RenderData';
import { FACTORY_HEALTH_TYPES } from '../../../constants/FactoryHealthConstants';
import FactoryHealthTypeContext from '../../../context/factoryHealthContext';

const TreeViewContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

const TreeViewHeader = styled('div', {
  shouldForwardProp: (prop) => prop !== 'factoryHealthType',
})(({ theme, factoryHealthType }) => {
  let gridTemplate =
    factoryHealthType === FACTORY_HEALTH_TYPES.THROUGHPUT
      ? '28.06% 16.15% 23.73% 23.28% 8.75%'
      : '50% 25% 25%';

  return {
    backgroundColor: theme.palette.background.darkishBlackBlue,
    minHeight: '3em',
    display: 'grid',
    gridTemplateColumns: gridTemplate,
    alignItems: 'center',
    '& > :first-of-type': {
      paddingLeft: '0.5rem',
    },

    '& .heading-container': {
      display: 'flex',
      alignItems: 'center',

      '& .heading-tooltip': {
        display: 'flex',
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
      },
      '& .heading-container-heading': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        color: theme.palette.text.lightUnitGrey,
      },
    },
  };
});

const HoverBox = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hoverBoxPosition',
})(({ theme, hoverBoxPosition }) => ({
  width: '100%',
  height: '2.5em',
  backgroundColor: theme.palette.background.blackGrey,
  position: 'absolute',
  top: hoverBoxPosition.top,
  left: hoverBoxPosition.left,
}));

const StyledTreeView = styled(TreeView)(() => ({
  paddingRight: 0,
  height: '35rem',
  overflowY: 'auto',
  scrollBehavior: 'smooth',
  '& .MuiTreeItem-root': {
    marginBottom: '1rem',
  },
  position: 'relative',
  width: '100%',
}));

const MainTree = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  width: '100%',
}));

const TreePanelContext = createContext();

export const useTreePanelContext = () => useContext(TreePanelContext);

const TreePanel = forwardRef(
  (
    {
      data = {},
      selectedNodes = [],
      selected = [],
      handleSelect,
      expanded = [],
      handleToggle,
      header = null,
      LabelComponent = () => null,
      dynamicHeightFactor = 1.5,
      treeWidth,
    },
    ref
  ) => {
    const mainTreeRef = useRef();
    const factoryHealthType = useContext(FactoryHealthTypeContext);

    const liDynamicHeight = mainTreeRef.current
      ? `${mainTreeRef.current.scrollHeight * dynamicHeightFactor}px`
      : '10rem';

    const [hoverBoxPosition, setHoverBoxPosition] = useState({
      left: -9999,
      top: 0,
    });

    function onMouseEnter(e) {
      if (!e.target.classList.contains('MuiTreeItem-content')) {
        return;
      }
      const { top } = e.target.getBoundingClientRect();
      const { y } = mainTreeRef.current.getBoundingClientRect();
      setHoverBoxPosition({ top: top - y, left: 0 });
    }

    function onMouseLeave() {
      setHoverBoxPosition({
        top: 0,
        left: -9999,
      });
    }

    const providerValue = useMemo(
      () => ({
        onMouseEnter,
        onMouseLeave,
      }),
      []
    );

    return (
      <TreeViewContainer>
        <TreeViewHeader factoryHealthType={factoryHealthType}>
          {header}
        </TreeViewHeader>
        <StyledTreeView
          ref={(r) => {
            // eslint-disable-next-line no-param-reassign
            ref.current = r;
          }}
          selected={selected}
          expanded={expanded}
          defaultSelected={selectedNodes}
          defaultExpanded={selectedNodes}
          onNodeToggle={handleToggle}
          onNodeSelect={handleSelect}
        >
          <li
            style={{
              height: liDynamicHeight,
            }}
          />
          <HoverBox hoverBoxPosition={hoverBoxPosition} />
          <MainTree ref={mainTreeRef}>
            <TreePanelContext.Provider value={providerValue}>
              <RenderData
                key="mainTree"
                nodes={data}
                index={-1}
                selected={selected}
                LabelComponent={LabelComponent}
                treeWidth={treeWidth}
                depth={0}
              />
            </TreePanelContext.Provider>
          </MainTree>
        </StyledTreeView>
      </TreeViewContainer>
    );
  }
);

TreePanel.displayName = 'Tree Panel';
TreePanel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  treeWidth: PropTypes.number,
  data: PropTypes.object,
  selectedNodes: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.arrayOf(PropTypes.string),
  handleSelect: PropTypes.func,
  expanded: PropTypes.arrayOf(PropTypes.string),
  handleToggle: PropTypes.func,
  header: PropTypes.node,
  dynamicHeightFactor: PropTypes.number,
  LabelComponent: PropTypes.func,
};

export default TreePanel;
