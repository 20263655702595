import { styled } from '@mui/material';
import infoIcon from '../../../../assets/img/infoIcon.svg?url';
import PropTypes from 'prop-types';
import { TEST_IDS } from '../../../../constants/FactoryHealthConstants';
import CustomTooltip from '../../../../styledComponents/CustomTooltip';
import DynamicTooltipHeader from '../../../../components/DynamicTooltip';
import TooltipDetails from '../../../../components/TooltipDetails';

const InfoIcon = styled('img')(() => ({
  width: '1em',
  height: '1em',
}));

const HeaderComponent = ({ headers = [] }) => {
  const components = headers.map(({ heading, tooltip }) => (
    <div className="heading-container" key={heading}>
      <DynamicTooltipHeader heading={heading} />

      {tooltip && (
        <div className="heading-tooltip">
          <CustomTooltip
            data-testid={`${TEST_IDS.TREE_HEADER_THROUGHPUT_TOOLTIP}-${heading}`}
            title={<TooltipDetails heading={tooltip[0]} content={tooltip[1]} />}
            placement="bottom-start"
            heading={heading}
          >
            <InfoIcon
              src={infoIcon}
              alt="info icon"
              aria-label={`${tooltip[0]}${tooltip[1]}`}
            />
          </CustomTooltip>
        </div>
      )}
    </div>
  ));
  return <>{components}</>;
};

HeaderComponent.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      tooltip: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};

export default HeaderComponent;
