import {
  MESSAGE_STRINGS,
  FACTORY_HEALTH_TYPES,
  ACTUALS_CONSTANTS,
} from '../constants/FactoryHealthConstants';
export const validateActualsRefreshRate = (val, unit) => {
  let errorMessage = '';
  if (val !== '') {
    const n = Number(val);
    let refreshRateIsValid = '';
    switch (unit) {
      case 'Seconds':
        refreshRateIsValid =
          !val.includes('.') && Number.isInteger(n) && n >= 90;
        errorMessage = refreshRateIsValid
          ? ''
          : MESSAGE_STRINGS['ERROR_MESSAGES.secondsValidation'];
        break;
      case 'Minutes':
        refreshRateIsValid =
          !val.includes('.') && Number.isInteger(n) && n >= 5;
        errorMessage = refreshRateIsValid
          ? ''
          : MESSAGE_STRINGS['ERROR_MESSAGES.minutesValidation'];
        break;
      case 'Hours':
        refreshRateIsValid = !val.includes('.') && Number.isInteger(n) && n > 0;
        errorMessage = refreshRateIsValid
          ? ''
          : MESSAGE_STRINGS['ERROR_MESSAGES.hoursValidation'];
        break;
    }
  }
  return errorMessage;
};

export const validateActualsEmptyFields = (
  refreshRateValue,
  refreshRateUnit,
  timeRange
) => {
  let emptyFields;
  if (timeRange === ACTUALS_CONSTANTS.DEFAULT_DROPDOWN_VALUE) {
    emptyFields = ['timeRange'];
  }
  if (refreshRateValue === '') {
    emptyFields = emptyFields
      ? [...emptyFields, 'refreshRateValue']
      : ['refreshRateValue'];
  }
  if (refreshRateUnit === ACTUALS_CONSTANTS.DEFAULT_DROPDOWN_VALUE) {
    emptyFields = emptyFields
      ? [...emptyFields, 'refreshRateUnit']
      : ['refreshRateUnit'];
  }
  return { emptyFields: emptyFields };
};

export const validateMaximumCapacity = (theoretical, demonstrated) => {
  let errorMessage = '';
  let emptyFields;
  if (+theoretical < +demonstrated) {
    errorMessage =
      MESSAGE_STRINGS[
        'ERROR_MESSAGES.theoreticalGreaterThanDemonstratedValidation'
      ];
  }
  if (theoretical === '' || theoretical === null || theoretical === undefined) {
    errorMessage = MESSAGE_STRINGS['ERROR_MESSAGES.mandatoryFieldsValidation'];
    emptyFields = ['theoretical'];
  }
  if (
    demonstrated === '' ||
    demonstrated === null ||
    demonstrated === undefined
  ) {
    errorMessage = MESSAGE_STRINGS['ERROR_MESSAGES.mandatoryFieldsValidation'];
    emptyFields = emptyFields
      ? [...emptyFields, 'demonstrated']
      : ['demonstrated'];
  }

  return {
    errorMessage: errorMessage,
    ...(emptyFields && { emptyFields: emptyFields }),
  };
};

export const getUtilityName = (factoryHealthType) => {
  if (factoryHealthType === FACTORY_HEALTH_TYPES.THROUGHPUT) {
    return MESSAGE_STRINGS['FACTORY_HEALTH.type.throughput'];
  } else if (factoryHealthType === FACTORY_HEALTH_TYPES.WIP) {
    return MESSAGE_STRINGS['FACTORY_HEALTH.type.wip'];
  }
  return null;
};

export const convertCase = (status) => {
  return status
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/(?: |\b)(\w)/g, function (key) {
      return key.toUpperCase();
    });
};

export const simulateUrlClick = (url, fileAcceptType) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('type', fileAcceptType);
  link.setAttribute('download', true);
  link.setAttribute('referrerpolicy', 'same-origin');
  link.click();
};

export const getHierarchyLabel = (entityType, entityName, entityNumber) => {
  return `${entityType}: ${
    entityType === 'Plant' ? entityName : `${entityNumber}-${entityName}`
  } `;
};

/**
 * Function to indicate Alert message shown
 * @param {String} fhType [THROUGHPUT, WIP]
 * @param {Boolean} isTargetInSyncWithEI
 * @param {Boolean} isTargetInSyncWithSchedule
 * @returns Boolean value indicating whether Alert should be displayed
 */
export const isAlertMessageShown = (
  fhType,
  isTargetInSyncWithEI,
  isTargetInSyncWithSchedule
) => {
  if (fhType === FACTORY_HEALTH_TYPES.THROUGHPUT) {
    return !isTargetInSyncWithEI || !isTargetInSyncWithSchedule;
  }
  return !isTargetInSyncWithEI;
};

/**
 * Function to written Alert Message for FH targets
 * @param {Boolean} isTargetSyncWithEI
 * @param {Boolean} isTargetSyncWithSchedule
 * @returns String Alert message based on scenario
 */
export const getAlertMessage = (
  isTargetSyncWithEI,
  isTargetSyncWithSchedule
) => {
  if (!isTargetSyncWithEI) {
    return MESSAGE_STRINGS['TARGETS_AIP_SYNC_ALERT'];
  }
  if (!isTargetSyncWithSchedule) {
    return MESSAGE_STRINGS['Targets.Alert.ScheduleUpdate'];
  }
  return '';
};
